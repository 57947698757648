import {
    Box, CircularProgress, Alert, Typography, Divider, Accordion,
    AccordionSummary, AccordionDetails, Button,
    Paper, AppBar, Toolbar, Tab, Tabs
} from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getActiveTaxonomySchemes, updateTaxonomy } from "../../api/taxonomies";
import { ApiDetailedTaxonomy, ApiSchemes, MultiLingualElement } from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import DataTable from "../dataTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContainerWrapper } from "../containerWrapper";
import { Loader } from "../loader";
import { theme } from "../../helpers/theme";
import { TaxonomyDetails } from "./subTaxonomyPages/taxonomyDetails";
import { TaxonomyEntryPoints } from "./subTaxonomyPages/taxonomyEntryPoints";
import { TaxonomyElements } from "./subTaxonomyPages/taxonomyElements";
import { TaxonomyDimensionValues } from "./subTaxonomyPages/taxonomyDimensionValues";
import { TaxonomyViewer } from "./subTaxonomyPages/taxonomyViewer";
import { t } from "@lingui/macro";

export interface TaxonomyDetailsDialogProps {
    open: boolean;
    taxonomyId: string | undefined;
    onClose: (updateStatus: "none" | "success" | "error", reason?: string) => void;
}

const DISPLAY_TO_BACKEND_MAPPING: Record<string, string> = { "Euro Fillings": "EuroFilling" };
const BACKEND_TO_DISPLAY_MAPPING: Record<string, string> = { "EuroFilling": "Euro Fillings" };

type IstarterPages = "details" | "entrypoints" | "taxonomyelements" | "taxonomyDimensionValues" | "viewer" | "logInformation";

export const TaxonomyById = ({ starterPage }: { starterPage: IstarterPages }) => {
    const { id } = useParams();
    const location = useLocation();

    const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
    const [updating, setUpdating] = useState(false);
    const [originalTitle, setOriginalTitle] = useState("");
    const [permissionScheme, setPermissionScheme] = useState<ApiDetailedTaxonomy["permissionScheme"] | null>(null);
    const [allPermissionSchemes, setAllPermissionSchemes] = useState<ApiSchemes[]>([]);
    const [initialData, setInitialData] = useState<{
        type: string;
        systemName: string | undefined;
        permissionScheme: ApiDetailedTaxonomy["permissionScheme"];
        validatingAuthority: string | null;
    }>({
        type: "",
        systemName: "",
        validatingAuthority: null,
        permissionScheme: {
            id: "",
            name: "",
        },
    });

    const { data, loading, error, setData } = useGetQuery<ApiDetailedTaxonomy>(
        `/api/taxonomies/${id}?cb=${cacheBuster}`, !id
    );

    useEffect(() => {
        if (data) {
            setPermissionScheme(data.permissionScheme || null);
            if (!initialData.systemName) {
                setInitialData({
                    type: BACKEND_TO_DISPLAY_MAPPING[data.type] || data.type,
                    systemName: data.systemName,
                    permissionScheme: data.permissionScheme ? data.permissionScheme : { id: "", name: "" },
                    validatingAuthority: BACKEND_TO_DISPLAY_MAPPING[data.type] === "Euro Fillings" ? data.validatingAuthority : null,
                });
            }
        }
    }, [data]);

    useEffect(() => {
        if (data && !allPermissionSchemes.length) {
            const fetchPermissionSchemes = async () => {
                const response = await getActiveTaxonomySchemes(cacheBuster);
                setAllPermissionSchemes(response.data);
            };
            fetchPermissionSchemes();
        }
    }, [data, cacheBuster, allPermissionSchemes.length]);

    if (location.pathname === `/taxonomies/${id}/`) {
        window.location.href = `/taxonomies/${id}/details`;
    }

    const handleFieldValueChange = (key: keyof ApiDetailedTaxonomy | string | undefined, value: string) => {
        if (key) {
            if (!originalTitle) {
                setOriginalTitle(data?.systemName || "");
            }
            const updated = { ...data };
            if (key === "type") {
                const mappedValue = DISPLAY_TO_BACKEND_MAPPING[value] || value;
                (updated as any)[key] = mappedValue;
                if (mappedValue === "Default") {
                    updated.validatingAuthority = null;
                }
            } else {
                (updated as any)[key] = value;
            }
            setData(updated as ApiDetailedTaxonomy);
        }
    };

    const handleSubmit = async () => {
        setUpdating(true);
        try {
            if (data) {
                await updateTaxonomy(id as string, {
                    systemName: data.systemName,
                    permissionScheme: permissionScheme?.id || null,
                    type: data.type,
                    validatingAuthority: data.type === "EuroFilling" && !data.validatingAuthority ? null : data.validatingAuthority || null,
                });
            }
        } catch (error) {
            console.error("Error updating taxonomy:", error);
        } finally {
            setUpdating(false);
            setCacheBuster(crypto.randomUUID());
        }
    };

    const navBarItems = [
        { key: "details", label: "Details" },
        { key: "entrypoints", label: "Entry Points" },
        { key: "taxonomyelements", label: "Taxonomy Elements" },
        { key: "taxonomydimensionvalues", label: "Taxonomy Dimension Values" },
        /* { key: "viewer", label: "Viewer" },
        { key: "loginformation", label: "Log Information" }, */
    ];

    const tabElements = navBarItems.map((item) => (
        <Tab
            key={item.key}
            component={Link}
            to={`/taxonomies/${id}/${item.key}`}
            value={`/taxonomies/${id}/${item.key}`}
            label={item.label}
            style={{ color: theme.palette.common.black }}
            {...a11yProps(item.label)}
        />
    ));

    return (
        <>
            <AppBar sx={{ mt: 9, height: 48, zIndex: theme.zIndex.drawer - 1, backgroundColor: "#f8f8f8", display: "flex", justifyContent: "center" }}>
                <Toolbar>
                    <Tabs value={location.pathname.toLowerCase()}>{tabElements}</Tabs>
                </Toolbar>
            </AppBar>
            <ContainerWrapper>
                <Paper sx={{ p: 5, mt: 2, maxHeight: "75vh" }} elevation={0}>
                    {loading ? (
                        <Box sx={{ minWidth: 500, minHeight: 500 }}><Loader height={500} /></Box>
                    ) : error ? (
                        <Alert severity="error">{t`Could not load taxonomy details. Please try again later`}</Alert>
                    ) : data ? (
                        <>
                            <Typography variant="h4">{originalTitle || data.systemName}</Typography>


                            {/* 
                                : (
                                <>
                                    {renderAccordion("Publisher", data.publisher?.names, "publisher")}
                                    {renderAccordion("Names", data.names, "names")}
                                    {renderAccordion("Descriptions", data.descriptions, "descriptions")}
                                </>
                            )
                            */}

                            {starterPage === "details" && (
                                <TaxonomyDetails
                                    taxonomydata={data}
                                    setData={setData}
                                    taxonomySchemes={allPermissionSchemes}
                                    permissionScheme={permissionScheme}
                                    setPermissionScheme={setPermissionScheme}
                                />
                            )}
                            {starterPage === "entrypoints" ? <TaxonomyEntryPoints /> : null}
                            {starterPage === "taxonomyelements" ? <TaxonomyElements /> : null}
                            {starterPage === "taxonomyDimensionValues" ? <TaxonomyDimensionValues /> : null}
                            {starterPage === "viewer" ? <TaxonomyViewer /> : null}
                            <Divider />
                            <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
                                <Button variant="contained" onClick={handleSubmit} disabled={updating || (initialData.systemName === data.systemName && initialData.type === data.type && data.permissionScheme?.id === permissionScheme?.id)}>
                                    {updating ? <CircularProgress size={25} /> : "Update"}
                                </Button>
                            </Box>
                        </>
                    ) : null}
                </Paper>
            </ContainerWrapper>
        </>
    );
};

function a11yProps(value: string) {
    return { id: `simple-tab-${value}`, "aria-controls": `simple-tabpanel-${value}` };
}

export default TaxonomyById;
