import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
    Select,
    MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { t } from "@lingui/macro";
import { cLabel } from "../api/customObjectsTypes";
import EmptyTableAlert from "./emptyTableAlert";

interface LabelTableProps {
    data: cLabel[];
    setData: (labels: cLabel[]) => void;
    languages?: string[];
    editRole?: boolean;
}

export default function LabelTable({ data, setData, languages, editRole }: LabelTableProps) {
    const handleInputChange = (index: number, field: keyof cLabel, value: string) => {
        const newData = [...data];
        newData[index][field] = value;
        setData(newData);
    };

    const handleAddRow = () => {
        setData([...data, { lang: "", text: "", }]);
    };

    const handleDeleteRow = (index: number) => {
        const newData = data.filter((_, i) => i !== index);
        setData(newData);
    };

    return (
        <TableContainer component={Paper} sx={{ maxHeight: "1000px", borderRadius: 2, overflow: "hidden" }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow sx={{ backgroundColor: "primary.light" }}>
                        <TableCell sx={{ fontWeight: "bold", py: 1 }}>{t`Language`}</TableCell>
                        <TableCell sx={{ fontWeight: "bold", py: 1 }}>{t`Label`}</TableCell>
                        {editRole && <TableCell sx={{ fontWeight: "bold", py: 1 }}>{t`Role`}</TableCell>}
                        <TableCell align="right" sx={{ py: 1 }}>
                            <IconButton onClick={handleAddRow} color="primary" size="small">
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={editRole ? 4 : 3} sx={{ textAlign: "center", py: 2 }}>
                                <EmptyTableAlert
                                    title={t`No labels`}
                                    subTitle={t`There are no labels to display. Add a new label by clicking the button above.`}
                                    severity="info"
                                    showIcon
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {data.length > 0 &&
                        languages &&
                        data.map((row, index) => (
                            <TableRow key={index} hover sx={{ height: "50px" }}>
                                <TableCell sx={{ p: 0.5, minWidth: 100 }}>
                                    <Select
                                        value={row.lang}
                                        onChange={(e) => handleInputChange(index, "lang", e.target.value as string)}
                                        size="small"
                                        fullWidth
                                        displayEmpty
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                            "& .MuiSelect-select": { py: 0.5 },
                                            "&:focus": { backgroundColor: "transparent" },
                                        }}
                                    >
                                        {languages.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                {lang}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell sx={{ p: 0.5, minWidth: 180 }}>
                                    <TextField
                                        value={row.text}
                                        onChange={(e) => handleInputChange(index, "text", e.target.value)}
                                        size="small"
                                        fullWidth
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": { border: "none" },
                                                "& .MuiInputBase-input": { py: 0.5 },
                                            },
                                        }}
                                    />
                                </TableCell>
                                {editRole && (
                                    <TableCell sx={{ p: 0.5, minWidth: 130 }}>
                                        <Select
                                            value={row.role}
                                            onChange={(e) => handleInputChange(index, "role", e.target.value as string)}
                                            size="small"
                                            fullWidth
                                            displayEmpty
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                                "& .MuiSelect-select": { py: 0.5 },
                                                "&:focus": { backgroundColor: "transparent" },
                                            }}
                                        >
                                            {["end", "previous", "standard", "start", "total"].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                )}
                                <TableCell align="right" sx={{ pr: 2 }}>
                                    <IconButton onClick={() => handleDeleteRow(index)} color="primary" size="small">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
}
