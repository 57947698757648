import { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import {
    Dialog,
    AppBar,
    Tabs,
    Tab,
    DialogContent,
    Button,
    Box,
    Container,
    DialogActions,
    DialogTitle,
    DialogContentText,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import LabeledTextField from '../labeledTextField/labeledTextField';
import { Loader } from '../loader';
import {
    tagsTemplate,
    headerTemplate,
    customDimensionsTemplate,
    dimensionValueTemplate,
    extensionElementsTemplate,
    settingsTemplate,
    ContextSettingsTemplate,
    taxonomyElementTemplate,
    labelsTemplate,
} from './customObjectsPreData';
import { t } from '@lingui/core/macro';
import { cContextSettingsDetails, cExtensionElementsDetails, cHeaderDetails, cLabel, cLabelDetails, cSettingsDetails, cTagDetails, cTaxonomyElement, dataMatchingToNavBar, ElementReference, expandedTaxonomyElement, ISelectTemplate, SectionPr } from '../../api/customObjectsTypes';
import GenericGridTable from './genericGridTable';
import axios from 'axios';
import LabelTable from '../labelTable';
import UsageComponent from './usageComponent';
import { mapDataToTemplate, mapTemplateToData } from './mapDataToTemplate';
import DataTableWithFetch from '../dataTableWitchFetch';
import { getAlternativeColumnsMapping } from './columnsMapping';
import { getLanguages } from '../../api/customObjects';
import { generalPut, generalPost } from '../../api/general';
import tokenStorageHelper from '../../helpers/tokenStorage';
import TaxonomyList from './taxonomyList';
import { getSelectedTaxonomyIds } from '../../helpers/taxonomyHelpers';

interface AddDialogProps {
    open: boolean;
    onClose: (updateStatus: "none" | "success" | "error", reason?: string) => void;
    selectTemplate: ISelectTemplate;
    id: string | undefined;
    url: string;
}

const CustomObjectsDialog = ({ open, onClose, selectTemplate, id, url }: AddDialogProps) => {
    const [data, setData] = useState<dataMatchingToNavBar | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("details");
    const [cacheBuster] = useState(crypto.randomUUID());
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const hasRun = useRef(false);
    const openDataDialog = useRef(false);
    const [languages, setLanguages] = useState<string[]>([]);

    useEffect(() => {
        if (!languages.length && (activeTab === "dimensionLabels" || activeTab === "documentation")) {
            getLanguages().then((response) => {
                setLanguages(response);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const templateDataSelected = useMemo(() => {
        switch (selectTemplate) {
            case "tags":
                return tagsTemplate();
            case "header":
                return headerTemplate();
            case "labels":
            case "tableItems":
                return labelsTemplate();
            case "customDimensions":
                return customDimensionsTemplate();
            case "dimensionValue":
                return dimensionValueTemplate();
            case "extensionElements":
                return extensionElementsTemplate();
            case "settings":
                return settingsTemplate();
            case "contextSettings":
                return ContextSettingsTemplate();
            case "taxonomyElement":
                return taxonomyElementTemplate();
            default:
                return tagsTemplate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTemplate]);

    type IncomingData = cTagDetails | cHeaderDetails | cLabelDetails | cExtensionElementsDetails | cSettingsDetails | cContextSettingsDetails | cTaxonomyElement;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (url && id) {
                    const response = await axios.get(`api/custom-objects/${url}/${id}?cb=${cacheBuster}`);
                    const apiData: IncomingData = response.data;
                    const mappedLabelsTemplate = mapDataToTemplate(templateDataSelected, apiData, selectTemplate);

                    setData(mappedLabelsTemplate);

                    const canDeleteThis = await axios.get(`api/custom-objects/${url}/${id}/can-delete`);
                    setCanDelete(canDeleteThis.data);
                } else {
                    const storedTaxonomies = getSelectedTaxonomyIds();
                    setData({
                        ...templateDataSelected,
                        taxonomies: storedTaxonomies || [],
                    });
                    setCanDelete(false);
                }

            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
                hasRun.current = true;
            }
        };

        if (!hasRun.current) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, id, templateDataSelected]);


    const handleTabChange = (event: any, newValue: string) => {
        setActiveTab(newValue);
    };

    const pagesShouldNotBeMapped = useMemo(() => [
        "globals",
        "usage",
        "dimensionValueReferences",
        "taxonomies",
        "extension",
        "childDimensionValues",
        "childElements",
        "documentation",
        "labels",
        "additionalElementReferences",
        "extensionStructureReferences",
        "hierarchyElementReferences",
        "customLabels",
        "customHeaders",
        "customTags",
        "customDimensionValues",
        "dimensionLabels",
        "contexts",
        "valueLabels",
        "validators",
        "childElementReferences",
        "notes",
    ], []);

    const handleApply = async () => {
        console.log(mapTemplateToData(data, id || null, url))
        const link = `api/custom-objects/${url}${id ? `/${id}` : ''}`;
        const method = id ? generalPut : generalPost;

        try {
            await method(link, mapTemplateToData(data, id || null, url));
            console.log(mapTemplateToData(data, id || null, url))
            hasRun.current = false;
            openDataDialog.current = true;
            setActiveTab("details");
            onClose("success", `${id ? t`Updated Successfully` : t`Created Successfully`}`);
        } catch (error: any) {
            console.error("Error during apply:", error);
            const errorMessage = error?.response?.data?.isTranslated ? error?.response?.data?.exceptions[0] : t`Failed to update`;
            hasRun.current = false;
            openDataDialog.current = true;
            setActiveTab("details");
            onClose("error", errorMessage);
        }
    };

    const handleClose = () => {
        onClose("none");
        hasRun.current = false;
    };

    const handleChange = (
        event: ElementReference | cTaxonomyElement | string | boolean,
        key: string,
        emptyParentAndChild?: boolean
    ) => {
        let inputValue: any;
        if (typeof event === "string" || typeof event === "boolean") {
            inputValue = event;
        } else if (event && typeof event === "object" && 'target' in event && event.target instanceof HTMLInputElement) {
            const { type, checked, value } = event.target;
            inputValue = type === "checkbox" ? checked : value;
        } else {
            inputValue = event;
        }

        setData((prevData: dataMatchingToNavBar | null) => {
            if (!prevData || !prevData[activeTab]) return prevData;

            const updatedData = {
                ...prevData,
                [activeTab]: {
                    ...prevData[activeTab],
                    [key]: {
                        ...prevData[activeTab][key],
                        value: inputValue,
                    },
                },
            };

            if (emptyParentAndChild) {
                if (updatedData[activeTab].parentMember) {
                    updatedData[activeTab].parentMember = { ...updatedData[activeTab].parentMember, value: null };
                }
                if (updatedData[activeTab].childDimensionValues) {
                    updatedData[activeTab].childDimensionValues = {
                        ...updatedData[activeTab].childDimensionValues,
                        references: null,
                    };
                }
            }

            return updatedData;
        });
    };

    const handleGlobalChange = (
        event: ElementReference | cTaxonomyElement | string | boolean,
        key: string
    ) => {
        let inputValue: ElementReference | cTaxonomyElement | string | boolean;

        if (typeof event === "string" || typeof event === "boolean") {
            inputValue = event;
        } else if (event && typeof event === "object" && 'target' in event && event.target instanceof HTMLInputElement) {
            const { type, checked, value } = event.target;
            inputValue = type === "checkbox" ? checked : value;
        } else {
            inputValue = event;
        }

        setData((prevData: dataMatchingToNavBar | null) => {
            if (!prevData) return prevData;

            const updatedData = {
                ...prevData,
                globals: {
                    ...prevData.globals,
                    [key]: {
                        ...prevData.globals[key],
                        value: inputValue,
                    },
                },
            };

            return updatedData;
        });
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`api/custom-objects/${url}/${id}`);
            setIsDeleteDialogOpen(false);
            onClose("success", "deleted");
        } catch (error) {
            console.error("Error during deletion:", error);
            onClose("error", "delete failed");
        }
    };

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const tabKeys = Object.keys(data || {}).filter(key => key !== 'id' && key !== 'autoGenerated');

    const isButtonDisabledForRoles = () => {
        const isCompanyApplied = data?.details?.company?.value !== null;
        if (tokenStorageHelper.isUserCustomObjectAdmin) {
            return false;
        } else if (tokenStorageHelper.isUserCustomObjectEditor) {
            if (isCompanyApplied) {
                return false;
            }
            return true;
        }
        return true;
    };

    const isAutogenerated = () => {
        if (data?.autoGenerated) {
            return true;
        }
        return false;
    }

    console.log(activeTab, data)

    return (
        <>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{t`Delete Confirmation`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t`Are you sure you want to delete this item?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        {t`Cancel`}
                    </Button>
                    <Button onClick={handleDelete} color="error" variant="contained">
                        {t`Delete`}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                maxWidth={false}
                fullWidth
                onClose={handleClose}
                sx={{
                    maxWidth: window.innerWidth > 1920 ? '50vw' : '90vw',
                    margin: 'auto',
                    maxHeight: "90vh",
                    p: 0,
                }}
            >
                <AppBar position="static">
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        {tabKeys.filter(key => key !== 'globals' && key !== 'hiddenValues').map(key => (
                            <Tab
                                sx={{ color: "white" }}
                                key={key}
                                value={key}
                                label={data?.[key]?.navBarName || key}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <DialogContent sx={{
                    maxHeight: "85vh",
                    minHeight: "60vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%",
                    minWidth: "100%",
                    p: 0,
                    pt: 2
                }}>
                    {isLoading ? (
                        <Loader height={500} />
                    ) : (<>
                        {!pagesShouldNotBeMapped.includes(activeTab) && (
                            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", }} maxWidth={false}>
                                {Object.entries(data?.[activeTab] || {})
                                    .filter(([key, item]: [string, any]) => {
                                        const isValidItem = (item: any) =>
                                            !(item.key === "" && item.name === "" && item.value === null);
                                        return item !== undefined && isValidItem(item);
                                    })
                                    .map(([key, item]: [string, any], index: number) => (
                                        key !== 'navBarName' &&
                                        key !== undefined && (
                                            <Grid size={12} key={key + index}>
                                                <LabeledTextField
                                                    id={id}
                                                    endpointUrl={item?.endpointUrl || undefined}
                                                    label={item?.name || ""}
                                                    placeholder={item?.placeholder || ""}
                                                    options={item?.options || []}
                                                    fieldType={item?.fieldType}
                                                    value={item?.value}
                                                    onValueChange={(event: any) => {
                                                        item?.isAlternate === "alternateDimension" ?
                                                            handleChange(event, key, true) :
                                                            handleChange(event, key)
                                                    }}
                                                    disabled={item?.isAlternate === "alternateParentMember" ? data?.details?.dimension?.value === null : item?.disabled}
                                                    alternative={item?.alternative}
                                                    urlType={item?.urlType}
                                                    page={data?.id !== "labels" && data?.id !== "dimensionValue" ? "normal" :
                                                        data?.id === "labels" ? "label" : "dimensionValue"
                                                    }
                                                    includeCustom={data?.id === "dimensionValue" ? true : data?.id === "extensionElements" ? false : null}
                                                    isAlternate={item?.isAlternate}
                                                    dimensionName={item?.isAlternate === "alternateParentMember" ? data?.details?.dimension?.value?.name : null}
                                                />
                                            </Grid>
                                        )
                                    ))}
                            </Container>
                        )}

                        {pagesShouldNotBeMapped.includes(activeTab) && (
                            <>
                                {activeTab === "usage" && (<>
                                    <UsageComponent id={id} activeTab={"customHeader"} />
                                </>)}
                            </>
                        )}

                        {pagesShouldNotBeMapped.includes(activeTab) &&
                            (activeTab === "customHeaders" ||
                                activeTab === "customTags" ||
                                activeTab === "contexts" ||
                                activeTab === "customLabels" ||
                                activeTab === "customDimensionValues"
                            ) && (
                                <DataTableWithFetch
                                    id={id || ""}
                                    activeTab={activeTab || ""}
                                    endpoint={data?.id || ""}
                                    columns={
                                        getAlternativeColumnsMapping(activeTab)
                                    }
                                    data={data?.[activeTab].references || []}
                                    noDataMessage={t`No data found`}
                                />
                            )}

                        {(activeTab === "dimensionLabels" || activeTab === "documentation") && (
                            <>
                                <LabelTable
                                    data={Object.values(data?.[activeTab]?.labels ?? {})}
                                    languages={languages}
                                    editRole={data?.id === "extensionElements" && activeTab === "dimensionLabels" ? true : false}
                                    setData={(newData: cLabel[]) => {
                                        setData((prevData: dataMatchingToNavBar | null) => {
                                            if (!prevData) return prevData;
                                            const updatedLabels = newData.reduce((acc, label) => {
                                                acc[label.text] = label;
                                                return acc;
                                            }, {} as Record<string, cLabel>);
                                            const updatedData = {
                                                ...prevData,
                                                [activeTab]: {
                                                    ...prevData[activeTab],
                                                    labels: updatedLabels
                                                }
                                            };
                                            return updatedData;
                                        });
                                    }}
                                />
                            </>
                        )}

                        {pagesShouldNotBeMapped.includes(activeTab) && (
                            <>
                                {(activeTab === "extensionStructureReferences" ||
                                    activeTab === "hierarchyElementReferences" ||
                                    activeTab === "additionalElementReferences" ||
                                    activeTab === "dimensionValueReferences" ||
                                    activeTab === "childDimensionValues" ||
                                    activeTab === "childElementReferences" ||
                                    activeTab === "validators"
                                ) && (
                                        <>
                                            {/* make dence */}
                                            <GenericGridTable
                                                dimensionName={activeTab === "childDimensionValues" ? data?.details?.dimension?.value?.name : null}
                                                type={activeTab ? (
                                                    activeTab === "dimensionValueReferences" &&
                                                    (data?.id === "header" || data?.id === "labels"))
                                                    ? "headerDimensionValues" : activeTab : "basicList"}
                                                id={data?.id || ""}
                                                /* isEdiTable={
                                                    activeTab === "dimensionValueReferences" &&
                                                        (data?.id === "header" || data?.id === "labels" || data?.id === "tags")
                                                        ? true : false
                                                } */
                                                isEdiTable={false}
                                                data={data?.[activeTab]?.references || []}
                                                setData={(newData: expandedTaxonomyElement) => {
                                                    setData((prevData: dataMatchingToNavBar | null) => {
                                                        if (!prevData) return prevData;
                                                        return {
                                                            ...prevData,
                                                            [activeTab]: {
                                                                ...prevData[activeTab],
                                                                references: newData,
                                                            },
                                                        };
                                                    });
                                                }}
                                            />

                                            {data?.globals && (
                                                <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", p: 0, m: 0 }} maxWidth="xl" >
                                                    {Object.entries(data.globals)
                                                        .filter(([key, item]: [string, any]) => {
                                                            const isValidItem = (item: SectionPr) =>
                                                                !(item.key === "" && item.name === "" && item.value === null);
                                                            return item !== undefined && isValidItem(item) && !(activeTab === "dimensionValueReferences" && key === "customTaxonomyGroup");
                                                        })
                                                        .map(([key, item]: [string, any], index: number) => (
                                                            (activeTab === "dimensionValueReferences" || key !== "overrideTypedMemberValueReference") && (
                                                                <Grid size={12} key={key + index}>
                                                                    <LabeledTextField
                                                                        id={id}
                                                                        endpointUrl={item?.endpointUrl || undefined}
                                                                        label={item?.name || ""}
                                                                        placeholder={item?.placeholder || ""}
                                                                        options={item?.options || []}
                                                                        fieldType={item?.fieldType}
                                                                        value={item?.value}
                                                                        onValueChange={(event: any) => handleGlobalChange(event, key)}
                                                                        alternative={item?.alternative}
                                                                        showDetailed={key === "overrideTypedMemberValueReference" ? false : true}
                                                                        isAlternate={item?.isAlternate}
                                                                    />
                                                                </Grid>
                                                            )
                                                        ))}
                                                </Container>
                                            )}
                                        </>

                                    )}
                                {activeTab === "taxonomies" && (
                                    <TaxonomyList
                                        selectedTaxonomies={data?.taxonomies || []}
                                        setSelectedTaxonomies={(selectedTaxonomies: string[]) => {
                                            setData((prevData: dataMatchingToNavBar | null) => {
                                                if (!prevData) return prevData;
                                                return {
                                                    ...prevData,
                                                    taxonomies: selectedTaxonomies,
                                                };
                                            });
                                        }}
                                    />
                                )}
                            </>
                        )}

                        {activeTab === "notes" && (
                            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", }} maxWidth={false}>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth
                                        sx={{
                                            width: "100%",
                                            margin: "0 0 10px 0",
                                        }}
                                        rows={15}
                                        label={t`Notes`}
                                        multiline
                                        value={data?.notes?.value}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setData((prevData: dataMatchingToNavBar | null) => {
                                                if (!prevData) return prevData;

                                                const updatedData = {
                                                    ...prevData,
                                                    notes: {
                                                        ...prevData.notes,
                                                        value: event.target.value,
                                                    },
                                                };

                                                return updatedData;
                                            });
                                        }}
                                    />
                                </Grid>
                            </Container>
                        )}
                    </>)}
                </DialogContent>
                <Box display="flex" justifyContent="flex-end" m={2}>
                    <Container
                        maxWidth={false}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid>
                            {id && canDelete && !isAutogenerated() && (
                                <>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            border: "1px solid #d32f2f",
                                            backgroundColor: "#d32f2f",
                                            ':hover': {
                                                backgroundColor: "#b71c1c"
                                            }
                                        }}
                                        onClick={handleOpenDeleteDialog}
                                    >
                                        {t`Delete`}
                                    </Button>
                                </>
                            )}
                        </Grid>
                        <Grid>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t`Close`}
                            </Button>
                            <Button sx={{ ml: 2 }}
                                disabled={isButtonDisabledForRoles() || isAutogenerated()}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleApply();
                                }}
                            >
                                {t`Apply`}
                            </Button>
                        </Grid>
                    </Container>
                </Box>
            </Dialog >
        </>
    );
};

export default CustomObjectsDialog;
