import { useState } from "react";
import { Box, Typography, Divider, Container, } from "@mui/material";
import { ApiDetailedTaxonomy, ApiSchemes } from "../../../api/types";
import LabeledTextField from "../../labeledTextField/labeledTextField";
import { t } from "@lingui/macro";
import { useGetQuery } from "../../../hooks/useGetQuery";
import LabelTable from "../../labelTable";

interface TaxonomyDetailsProps {
    taxonomydata: ApiDetailedTaxonomy;
    setData: (data: ApiDetailedTaxonomy) => void;
    taxonomySchemes: ApiSchemes[];
    permissionScheme: ApiDetailedTaxonomy["permissionScheme"] | null;
    setPermissionScheme: (scheme: ApiDetailedTaxonomy["permissionScheme"] | null) => void;
}

const DISPLAY_TO_BACKEND_MAPPING: { [key: string]: string } = {
    "Euro Fillings": "EuroFilling",
};

const BACKEND_TO_DISPLAY_MAPPING: { [key: string]: string } = {
    "EuroFilling": "Euro Fillings",
};

export const TaxonomyDetails = ({ taxonomydata, setData, taxonomySchemes, permissionScheme, setPermissionScheme }: TaxonomyDetailsProps) => {
    const { data, loading, error } = useGetQuery<ApiDetailedTaxonomy>(`/api/taxonomies/configurations/${taxonomydata?.id}`);

    const [originalTitle, setOriginalTitle] = useState("");

    const handleFieldValueChange = (key: keyof ApiDetailedTaxonomy | string | undefined, value: string) => {
        if (key) {
            if (!originalTitle) {
                setOriginalTitle(taxonomydata?.systemName || "");
            }
            const updated = { ...data };
            if (key === "type") {
                const mappedValue = DISPLAY_TO_BACKEND_MAPPING[value] || value;
                (updated as any)[key] = mappedValue;
                if (mappedValue === "Default") {
                    updated.validatingAuthority = null;
                }
            } else {
                (updated as any)[key] = value;
            }
            setData(updated as ApiDetailedTaxonomy);
        }
    };

    interface Icolumns {
        label: string;
        key: string;
        value: string | undefined;
        select?: boolean;
        options?: string[];
        isCheckBox?: boolean;
        disabled?: boolean;
        onchange?: boolean;
    }

    const ColumnItems: Icolumns[] = [
        { label: "Id:", key: "id", value: taxonomydata?.id, disabled: true },
        { label: "System Name:", key: "systemName", value: taxonomydata?.systemName, onchange: true },
        { label: "Package Name:", key: "packageName", value: taxonomydata?.packageName, disabled: true },
        { label: "Identifier:", key: "identifier", value: taxonomydata?.identifier, disabled: true },
        { label: "Full Package Name:", key: "fullPackageName", value: taxonomydata?.fullPackageName, disabled: true },
        { label: "System Type:", key: "type", value: BACKEND_TO_DISPLAY_MAPPING[taxonomydata?.type ?? ""] || taxonomydata?.type, select: true, options: ["Default", "Euro Fillings"], onchange: true },
        { label: "License Name:", key: "licenseName", value: taxonomydata?.license?.name, disabled: true },
        { label: "Validating Authority:", key: "validatingAuthority", value: taxonomydata?.validatingAuthority || "", select: true, options: (BACKEND_TO_DISPLAY_MAPPING[taxonomydata?.type ?? ""] || taxonomydata?.type) === "Euro Fillings" ? ["EBA", "EIOPA", "DNB", "SBR"] : [""], disabled: (BACKEND_TO_DISPLAY_MAPPING[data?.type ?? ""] || data?.type) !== "Euro Fillings", onchange: true },
        { label: "License Href:", key: "licenseHref", value: taxonomydata?.license?.href, disabled: true },
    ];

    return (
        <>
            <Box sx={{ pt: 2, pb: 2 }}>
                <Typography variant="h6">{t`General Information`}</Typography>
                <Divider />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {ColumnItems.map((item) => (
                    <Box sx={{ flex: "1 1 50%" }} key={item.key}>
                        <LabeledTextField
                            label={item.label}
                            value={item.value}
                            disabled={item.disabled}
                            fieldType={item.select ? "select" : "text"}
                            options={item.options}
                            onValueChange={item.onchange ? (e: any) => handleFieldValueChange(item.key, e.target.value) : undefined}
                        />
                    </Box>
                ))}
                <Box sx={{ flex: "1 1 50%" }}>
                    <LabeledTextField
                        label="Permission Scheme"
                        value={permissionScheme?.name || ""}
                        fieldType="select"
                        options={["", ...taxonomySchemes.map((item) => item.name)]}
                        onValueChange={(e: any) => {
                            if (e.target.value === "") {
                                setPermissionScheme(null);
                            } else {
                                const selected = taxonomySchemes.find((item) => item.name === e.target.value);
                                if (selected) {
                                    setPermissionScheme({ id: selected.id, name: selected.name });
                                }
                            }
                        }}
                    />
                </Box>
            </Box>
            <Typography variant="h4">{t`Labels`}</Typography>
            <Container maxWidth={false}>
                <Box sx={{ pt: 2, pb: 2 }}>
                    <LabelTable
                        data={[]}
                        setData={() => { }}
                    />
                </Box>
            </Container>
        </>
    );
};