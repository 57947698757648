import { ListItemText, Autocomplete, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useState, useEffect } from "react";
import { generalGet } from "../../api/general";
import { cParserType } from "../../api/customObjectsTypes";

export const ParserTypeLabel = ({
    label,
    value,
    endpoint,
    onValueChange,
    placeholder,
    disabled,
    setSelectedValue,
}: {
    label: string | undefined;
    value: number | null;
    endpoint: string;
    onValueChange?: (event: number | null) => void;
    setSelectedValue?: (value: number | null) => void;
    placeholder?: string;
    disabled?: boolean;
}) => {
    const [optionsState, setOptionsState] = useState<cParserType[]>([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await generalGet<cParserType[]>(endpoint);
                const data = response.data;
                setOptionsState(data || []);
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        };

        fetchOptions();
    }, [endpoint]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <Autocomplete
                    options={optionsState}
                    getOptionLabel={(option) => (typeof option === "object" && option !== null ? option.name : "")}
                    value={optionsState.find(option => option.value === value) ?? null}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            onValueChange?.(newValue.value);
                        }
                    }}
                    onBlur={(event) => {
                        const inputValue = (event.target as HTMLInputElement).value;
                        const matchingOptions = optionsState.filter(option =>
                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        if (matchingOptions.length === 1) {
                            onValueChange?.(matchingOptions[0].value);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};
