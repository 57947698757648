import { Container, darken, Typography } from "@mui/material";
import { pageSelector } from "../../api/customObjectsTypes";
import Grid from "@mui/material/Grid2";
import { getTaxonomyNames } from "../../helpers/taxonomyHelpers";
import { DimensionValueReference } from "../../api/customObjectsTypes";
interface ElementDetailedViewProps {
    element: DimensionValueReference;
    isEven?: boolean;
    isOption?: boolean;
    page?: pageSelector;
}

const GridView = ({ label, value, size }: { label: string; value: string | string[]; size: number }) => {
    return (
        <Grid size={size}>
            <Typography variant="subtitle2" component="div" sx={{ fontWeight: 'bold' }}>
                {label}
            </Typography>
            {Array.isArray(value) ? (
                value.map((val, index) => (
                    <Typography key={index} variant="body2">
                        {val}
                        <br />
                    </Typography>
                ))
            ) : (
                <Typography variant="body2">
                    {value}
                </Typography>
            )}
        </Grid>
    );
};

export const DimensionDetailedView = ({
    element,
    isEven,
    isOption,
    page = "normal",
}: ElementDetailedViewProps) => {
    const greenBackgroundColor = isEven ? "#defff0" : "#bee6d3";
    const backgroundColor = isEven ? "#e7e7e7" : "#ffffff";

    if (!element?.isExtension && (element?.taxonomyValue || element?.taxonomyDimension) && isOption) {
        return null;
    }

    if ((!element.isExtension && element.taxonomyValue && !isOption) || element?.taxonomyDimension) {
        return (
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    wordWrap: "break-word",
                    color: "black",
                    padding: 0.5,
                    backgroundColor: greenBackgroundColor,
                    flex: 1,
                    "&:hover ": {
                        backgroundColor: darken(greenBackgroundColor, 0.1),
                    },
                }}
            >
                <Grid container>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {element?.taxonomyValue && element.name}
                        {element?.taxonomyDimension && `${element.taxonomyDimension.namespaceAbbreviation}:${element.taxonomyDimension.name}`}
                    </Typography>
                </Grid>
            </Container>
        );
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                display: "flex",
                overflow: "hidden",
                wordWrap: "break-word",
                padding: 2,
                backgroundColor: backgroundColor,
                borderTop: "0.1px solid black",
                borderBottom: "0.1px solid black",
                flex: 1,
                transition: "background-color 0.1s ease",
                "&:hover ": {
                    backgroundColor: darken(backgroundColor, 0.1),
                },
            }}
        >
            <Grid container spacing={2} maxWidth={"false"} sx={{ flex: 1 }}>
                {page === "dimensionValue" && (
                    <>
                        <GridView label="Name" value={element?.name || ""} size={12} />
                        <GridView label="Company" value={element?.customDimensionValue?.company?.name || element?.extensionDimension?.company?.name || ""} size={8} />
                        <GridView label="Taxonomies" value={getTaxonomyNames(element?.customDimensionValue?.taxonomies || element?.extensionDimension?.taxonomies || []) || ""} size={4} />
                    </>
                )}
                {page === "normal" && (
                    <>
                        <GridView label="Dimension Value" value={element?.name || ""} size={8} />
                        <GridView label="Parent" value={element?.customDimensionValue?.parentMember?.valueName || ""} size={4} />
                        <GridView label="Company" value={element?.customDimensionValue?.company?.name || ""} size={4} />
                        <GridView label="Taxonomies" value={getTaxonomyNames(element?.customDimensionValue?.taxonomies || []) || ""} size={4} />
                        <GridView label="Children" value={element?.customDimensionValue?.childDimensionValues?.map(
                            (child) => child?.valueName || ""
                        ) || ""} size={4} />
                    </>
                )}
            </Grid>
        </Container>
    );
};
