import { t, Trans } from "@lingui/macro";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { createNewOrganization } from "../../api/organizations";
import { ApiOrganizationRequest } from "../../api/types";
import { assignDeep } from "../../helpers/assignDeep";
import {
  getUserEmailValidationError,
  getUserNameValidationError,
} from "../../helpers/validateNewUser";
import {
  getOrganizationCountryValidationError,
  getOrganizationDomainValidationError,
  getOrganizationLeiCodeValidationError,
  getOrganizationNameValidationError,
  getOrganizationRegNumberValidationError,
  isOrganizationRequestValid,
} from "../../helpers/validateOrganization";

export interface CreateOrganizationDialogProps {
  open: boolean;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => void;
}

const initial = {
  adminUser: {
    emailAddress: "",
    name: "",
  },
  country: "",
  domain: null,
  isCredentialAuthDisallowed: false,
  leiCode: null,
  licensing: {
    hasConverter: false,
    hasInspector: true,
    onlyAllowVisualFile: false,
    hasSelfService: false,
  },
  name: "",
  registrationNumber: null,
  SendSignUpEmail: false,
  IsDomainRequiredForUserSignup: true,
};

const CreateOrganizationDialog = ({
  open,
  onClose,
}: CreateOrganizationDialogProps) => {
  const [currentRequest, setCurrentRequest] =
    useState<ApiOrganizationRequest>(initial);
  const [saving, setSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [updateError, setUpdateError] = useState<string>();

  const resetDialog = () => {
    setCurrentRequest({
      adminUser: {
        emailAddress: "",
        name: "",
      },
      country: "",
      domain: null,
      isCredentialAuthDisallowed: false,
      leiCode: null,
      licensing: {
        hasConverter: false,
        hasInspector: true,
        onlyAllowVisualFile: false,
        hasSelfService: false,
      },
      name: "",
      registrationNumber: null,
      SendSignUpEmail: false,
      IsDomainRequiredForUserSignup: true,
    });
  };

  const handleFieldValueChange = (path: string, val: any) => {
    setCurrentRequest((prevRequest) => {
      const newRequest = { ...prevRequest };
      assignDeep(newRequest, val, path);
      setIsValid(
        isOrganizationRequestValid(
          newRequest,
          newRequest.IsDomainRequiredForUserSignup
        )
      );
      return newRequest;
    });
  };

  useEffect(() => {
    setIsValid(
      isOrganizationRequestValid(
        currentRequest,
        currentRequest.IsDomainRequiredForUserSignup
      )
    );
  }, [currentRequest]);

  const handleSubmit = async () => {
    setSaving(true);
    try {
      if (!currentRequest.IsDomainRequiredForUserSignup) {
        currentRequest.domain = null;
      }
      if (
        currentRequest.adminUser?.name.length === 0 &&
        currentRequest.adminUser?.emailAddress.length === 0
      ) {
        currentRequest.adminUser = null;
      }
      await createNewOrganization(currentRequest);
      onClose("success", t`Organization successfully created`);
      setSaving(false);
      resetDialog();
    } catch (ex: any) {
      setSaving(false);
      resetDialog();
      setUpdateError(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`
      );
    }
  };

  useEffect(() => {
    if (updateError) {
      setTimeout(() => {
        setUpdateError(undefined);
      }, 5000);
    }
  }, [updateError]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        onClose("none");
        resetDialog();
      }}
    >
      <>
        <DialogTitle>
          <Trans>Create Organization</Trans>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 210 }}>
          {saving && (
            <DialogContentText>
              <Trans>Hang in there, This might take a few minutes...</Trans>
            </DialogContentText>
          )}
          <Grid container spacing={2}>
            {!saving && (
              <>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    error={
                      currentRequest.name.length > 0 &&
                      getOrganizationNameValidationError(currentRequest.name)
                        .length > 0
                    }
                    value={currentRequest.name}
                    onChange={(e) =>
                      handleFieldValueChange("name", e.currentTarget.value)
                    }
                    label={t`Name*`}
                    helperText={
                      currentRequest.name.length > 0 &&
                      getOrganizationNameValidationError(currentRequest.name)
                        .length > 0
                        ? getOrganizationNameValidationError(
                            currentRequest.name
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    error={
                      currentRequest.country.length > 0 &&
                      getOrganizationCountryValidationError(
                        currentRequest.country
                      ).length > 0
                    }
                    value={currentRequest.country}
                    onChange={(e) =>
                      handleFieldValueChange("country", e.currentTarget.value)
                    }
                    label={t`Country*`}
                    helperText={
                      currentRequest.country.length > 0 &&
                      getOrganizationCountryValidationError(
                        currentRequest.country
                      ).length > 0
                        ? getOrganizationCountryValidationError(
                            currentRequest.country
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    error={
                      (currentRequest.registrationNumber?.length || -1) > 0 &&
                      getOrganizationRegNumberValidationError(
                        currentRequest.registrationNumber || ""
                      ).length > 0
                    }
                    value={currentRequest.registrationNumber}
                    onChange={(e) =>
                      handleFieldValueChange(
                        "registrationNumber",
                        e.currentTarget.value
                      )
                    }
                    label={t`Registration number`}
                    helperText={
                      (currentRequest.registrationNumber?.length || -1) > 0 &&
                      getOrganizationRegNumberValidationError(
                        currentRequest.registrationNumber || ""
                      ).length > 0
                        ? getOrganizationRegNumberValidationError(
                            currentRequest.registrationNumber || ""
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    error={
                      (currentRequest.leiCode &&
                        currentRequest.leiCode.length > 0 &&
                        getOrganizationLeiCodeValidationError(
                          currentRequest.leiCode
                        ).length > 0) ||
                      false
                    }
                    value={currentRequest.leiCode}
                    onChange={(e) =>
                      handleFieldValueChange("leiCode", e.currentTarget.value)
                    }
                    label={t`Lei code`}
                    helperText={
                      currentRequest.leiCode &&
                      currentRequest.leiCode.length > 0 &&
                      getOrganizationLeiCodeValidationError(
                        currentRequest.leiCode
                      ).length > 0
                        ? getOrganizationLeiCodeValidationError(
                            currentRequest.leiCode
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    value={currentRequest.domain}
                    onChange={(e) =>
                      handleFieldValueChange("domain", e.currentTarget.value)
                    }
                    disabled={!currentRequest.IsDomainRequiredForUserSignup}
                    label={t`Domain`}
                    helperText={
                      currentRequest.IsDomainRequiredForUserSignup &&
                      currentRequest.domain !== null &&
                      currentRequest.domain.length > 0 &&
                      getOrganizationDomainValidationError(
                        currentRequest.domain
                      ).length > 0
                        ? getOrganizationDomainValidationError(
                            currentRequest.domain
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    error={
                      currentRequest.adminUser !== null &&
                      currentRequest.adminUser.name.length > 0 &&
                      getUserNameValidationError(currentRequest.adminUser.name)
                        .length > 0
                    }
                    value={currentRequest.adminUser?.name}
                    onChange={(e) =>
                      handleFieldValueChange(
                        "adminUser.name",
                        e.currentTarget.value
                      )
                    }
                    label={t`Admin name`}
                    helperText={
                      currentRequest.adminUser !== null &&
                      currentRequest.adminUser.name.length > 0 &&
                      getUserNameValidationError(currentRequest.adminUser.name)
                        .length > 0
                        ? getUserNameValidationError(
                            currentRequest.adminUser.name
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    error={
                      currentRequest.IsDomainRequiredForUserSignup &&
                      currentRequest.adminUser !== null &&
                      currentRequest.domain !== null &&
                      currentRequest.adminUser.emailAddress.length > 0 &&
                      getUserEmailValidationError(
                        currentRequest.adminUser.emailAddress,
                        currentRequest.domain
                      ).length > 0
                    }
                    value={currentRequest.adminUser?.emailAddress}
                    onChange={(e) =>
                      handleFieldValueChange(
                        "adminUser.emailAddress",
                        e.currentTarget.value
                      )
                    }
                    label={t`Admin Email`}
                    helperText={
                      currentRequest.IsDomainRequiredForUserSignup &&
                      currentRequest.adminUser !== null &&
                      currentRequest.domain !== null &&
                      currentRequest.adminUser.emailAddress.length > 0 &&
                      getUserEmailValidationError(
                        currentRequest.adminUser.emailAddress,
                        currentRequest.domain
                      ).length > 0
                        ? getUserEmailValidationError(
                            currentRequest.adminUser.emailAddress,
                            currentRequest.domain
                          )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentRequest.licensing.hasConverter}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "licensing.hasConverter",
                            e.currentTarget.checked
                          )
                        }
                      />
                    }
                    label={t`Allow Converter`}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentRequest.licensing.hasInspector}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "licensing.hasInspector",
                            e.currentTarget.checked
                          )
                        }
                      />
                    }
                    label={t`Allow Inspector`}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentRequest.licensing.onlyAllowVisualFile}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "licensing.onlyAllowVisualFile",
                            e.currentTarget.checked
                          )
                        }
                      />
                    }
                    label={t`Only Allow Visual File`}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!currentRequest.IsDomainRequiredForUserSignup}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "IsDomainRequiredForUserSignup",
                            !e.currentTarget.checked
                          )
                        }
                      />
                    }
                    label={t`Do Not Require Domain`}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentRequest.licensing.hasSelfService}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "licensing.hasSelfService",
                            e.currentTarget.checked
                          )
                        }
                      />
                    }
                    label={t`Enable Self Service`}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        {updateError && (
          <DialogContentText sx={{ color: "red", textAlign: "center" }}>
            {updateError}
          </DialogContentText>
        )}
        <DialogActions>
          <Typography>{t`Send signup email`}</Typography>
          <Checkbox
            sx={{ mr: 1 }}
            checked={currentRequest?.SendSignUpEmail}
            onChange={(e) =>
              handleFieldValueChange("SendSignUpEmail", e.target.checked)
            }
          />
          <Button variant="outlined" onClick={() => onClose("none")}>
            <Trans>Close</Trans>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={saving || !isValid}
          >
            {!saving && <Trans>Save</Trans>}
            {saving && <CircularProgress size={25} />}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default CreateOrganizationDialog;
