import { useState, useEffect } from "react";
import { t } from "@lingui/macro";
import { getAllTaxonomies } from "../../helpers/taxonomyHelpers";
import { Checkbox, Container, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { TaxonomyListProps } from "../../api/customObjectsTypes";

interface cTaxonomyListProps {
    selectedTaxonomies: string[];
    setSelectedTaxonomies: (taxonomies: string[]) => void;
}

export const TaxonomyList = ({ selectedTaxonomies, setSelectedTaxonomies }: cTaxonomyListProps) => {
    const [localTaxonomies, setLocalTaxonomies] = useState<TaxonomyListProps[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchTaxonomies = async () => {
            try {
                const allTaxonomies = await getAllTaxonomies();
                const updatedTaxonomies = allTaxonomies.map((taxonomy) => ({
                    ...taxonomy,
                    isSelected: selectedTaxonomies.includes(taxonomy.id),
                }));
                setLocalTaxonomies(updatedTaxonomies as TaxonomyListProps[]);
            } catch (error) {
                setError(error instanceof Error ? error.message : String(error));
            }
        };

        fetchTaxonomies();
    }, [selectedTaxonomies]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;

        const updatedTaxonomies = localTaxonomies.map((taxonomy) => {
            if (taxonomy.id === value) {
                return {
                    ...taxonomy,
                    isSelected: checked,
                };
            }
            return taxonomy;
        });

        setLocalTaxonomies(updatedTaxonomies);
        setSelectedTaxonomies(updatedTaxonomies.filter((taxonomy) => taxonomy.isSelected).map((taxonomy) => taxonomy.id));
    }

    return (
        <Container sx={{ m: 0, p: 0 }}>
            <Typography variant="h5">{t`Select Taxonomies`}</Typography>
            {error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <FormControl fullWidth>
                    <FormGroup>
                        {localTaxonomies
                            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
                            .map(({ id, isSelected, name }) => (
                                <FormControlLabel
                                    key={id}
                                    control={
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={handleChange}
                                            value={id}
                                            sx={{ p: 0.5 }}
                                        />
                                    }
                                    label={name || id}
                                    sx={{ m: 0 }}
                                />
                            ))}
                    </FormGroup>
                </FormControl>
            )}
        </Container>
    );
};

export default TaxonomyList;