import { cCompany, contextSettingsValidationType, customDimensionsValidationType, customDimensionValueValidationType, dataMatchingToNavBar, dimensionReference, ElementReference, extensionElementsValidationType, headerValidationType, LabeledTextFieldProps, labelsValidationType, settingsValidationType, tagsValidationType } from "../../api/customObjectsTypes";
import { cloneDeep } from "lodash";

export const mapDataToTemplate = (
    template: dataMatchingToNavBar,
    data: dataMatchingToNavBar,
    selectTemplate: string
): dataMatchingToNavBar => {
    const mappedTemplate = cloneDeep(template);

    const updateFieldValue = (field: LabeledTextFieldProps, value: string | boolean | null | cCompany | ElementReference | dimensionReference) => {
        if (field && "fieldType" in field) {
            field.value = value ?? field.value;
        }
    };

    const updateTemplate = (section: LabeledTextFieldProps, data: dataMatchingToNavBar) => {
        Object.entries(section).forEach(([key, field]) => {
            if (typeof field === "object" && field !== null) {
                if ("fieldType" in field) {
                    updateFieldValue(field, data[key]);
                } else {
                    updateTemplate(field, data);
                }
            }
        });
    };

    const updateLanguage = (section: LabeledTextFieldProps, data: dataMatchingToNavBar) => {
        Object.entries(section).forEach(([key, field]) => {
            if (typeof field === "object" && field !== null) {
                if (key === "language" && "lang" in data) {
                    updateFieldValue(field, data.lang);
                } else {
                    updateLanguage(field, data);
                }
            }
        });
    };

    const updateReferences = (key: string, navBarName: string) => {
        if (key in data && Array.isArray(data[key])) {
            mappedTemplate[key] = {
                navBarName,
                references: data[key].map((ref: any) => ({ ...ref })),
            };
        }
    };

    Object.entries(mappedTemplate).forEach(([sectionKey, section]) => {
        if (typeof section === "object" && section !== null) {
            updateTemplate(section, data);
            updateLanguage(section, data);
        }
    });

    [
        { key: "dimensionValueReferences", name: "Dimension Values" },
        { key: "additionalElementReferences", name: "Additional Elements" },
        { key: "childElementReferences", name: "Child Elements" },
        { key: "extensionStructureReferences", name: "Extension Structures" },
        { key: "hierarchyElementReferences", name: "Extension" },
        { key: "elementReference", name: "Elements" },
        { key: "childDimensionValues", name: "Child Dimension Values" },
    ].forEach(({ key, name }) => updateReferences(key, name));

    const labelMapping: Record<string, string> = {
        dimensionValue: "Value Labels",
        settings: "Labels",
        extensionElements: "Labels",
        customDimensions: "Dimension Labels",
        labels: "Custom Labels",
    };

    if ("labels" in data) {
        const labelKey = labelMapping[selectTemplate] || labelMapping.labels;
        mappedTemplate[labelKey === "Custom Labels" ? "customLabels" : "dimensionLabels"] = {
            navBarName: labelKey,
            labels: { ...data.labels },
        };
    }

    if ("taxonomies" in data) {
        mappedTemplate.taxonomies = data.taxonomies;
        localStorage.setItem("selectedTaxonomies", JSON.stringify(data.taxonomies));
    }

    if ("notes" in data) {
        mappedTemplate.notes = {
            ...mappedTemplate.notes,
            value: data.notes
        };
    }

    if ('documentationLabels' in data) {
        mappedTemplate.documentation = {
            navBarName: 'Documentation',
            labels: data.documentationLabels
        }
    }

    if ("autoGenerated" in data) {
        mappedTemplate.autoGenerated = data.autoGenerated;
    }

    return mappedTemplate;
};


function capitalizeKeys(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(capitalizeKeys);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key.charAt(0).toUpperCase() + key.slice(1),
                capitalizeKeys(value)
            ])
        );
    }
    return obj;
}

/*   
------------------------------------------------------------------------ */
export const mapTemplateToData = (
    template: dataMatchingToNavBar | null,
    id: string | null,
    type: string
) => {
    if (!template) return {};
    const data: Record<string, any> = {};

    const capitalize = (str: string): string =>
        str.charAt(0).toUpperCase() + str.slice(1);

    const extractFieldValue = (field: any): any => {
        if (!field) return null;

        const value = field.value ?? null;

        if (value && typeof value === "object") {
            const processedValue = capitalizeKeys(value);
            return Object.keys(processedValue).length > 0 ? processedValue : null;
        }

        return value;
    };

    const processSection = (section: Record<string, any>, target: Record<string, any>): void => {
        Object.entries(section).forEach(([key, value]) => {
            if (value && typeof value === "object") {
                if ("fieldType" in value) {
                    target[capitalize(key)] = extractFieldValue(value);
                } else {
                    const capitalizedSection = {};
                    processSection(value, capitalizedSection);
                    target[capitalize(key)] = capitalizedSection;
                }
            }
        });
    };

    const flattenObject = (obj: any): any => {
        if (obj && typeof obj === "object" && !Array.isArray(obj)) {
            const keys = Object.keys(obj);

            const allNumeric = keys.every(key => !isNaN(Number(key)));

            if (allNumeric) {
                return keys.map(key => flattenObject(obj[key]));
            }

            return keys.reduce((acc: Record<string, any>, key: string) => {
                acc[key] = flattenObject(obj[key]);
                return acc;
            }, {} as Record<string, any>);
        }
        return obj;
    };

    const extractReferences = (
        key: string,
        source: Record<string, dataMatchingToNavBar>,
        target: Record<string, any>
    ): void => {
        const section = source[key];
        if (!section) return;

        const references = Array.isArray(section)
            ? section.map((ref: any) => (ref && typeof ref === "object" ? flattenObject(ref) : null))
            : Object.values(section).flatMap((ref: any) =>
                ref && typeof ref === "object" ? flattenObject(ref) : []
            );

        const filteredReferences = references.filter((ref) => ref && Object.keys(ref).length > 0);

        target[capitalize(key)] = filteredReferences.length > 0 ? filteredReferences : null;
    };

    Object.entries(template).forEach(([, value]) => {
        if (value && typeof value === "object") {
            processSection(value, data);
        }
    });

    [
        "childDimensionValues",
        "dimensionValueReferences",
        "additionalElementReferences",
        "childElementReferences",
        "extensionStructureReferences",
        "hierarchyElementReferences",
        "elementReference",
    ].forEach((key) => extractReferences(key, template, data));

    const extractLabels = (
        source: Record<string, any>,
        target: Record<string, any>
    ): void => {
        const labels = source.dimensionLabels?.labels || source.customLabels?.labels;
        target.Labels = labels && Object.keys(labels).length > 0 ? Object.values(labels) : null;
    };

    if (template.dimensionLabels || template.customLabels) {
        extractLabels(template, data);
    }

    if (template.documentation && template.documentation.labels) {
        data.DocumentationLabels = Object.values(template.documentation.labels);
    }

    if (template.notes) {
        data.Notes = template.notes.value || null;
    }

    if (template.taxonomies) data.Taxonomies = template.taxonomies;

    if (template.globals) {
        Object.entries(template.globals).forEach(([key, value]) => {
            if (value && typeof value === "object" && "fieldType" in value && "value" in value) {
                if (key === "overrideTypedMemberValueReference") {
                    data.OverrideTypedMemberValueReference = capitalizeKeys(value.value);
                } else {
                    data[capitalize(key)] = extractFieldValue(value);
                }
            }
        });
    }

    if (["labels", "headers", "table-items"].includes(type)) {
        data.Table = data.Table && Object.keys(data.Table).length > 0 ? data.Table : null;
        data.Row = data.Row && Object.keys(data.Row).length > 0 ? data.Row : null;
        if (type === "headers") {
            data.IsCustomHeader = true;
        }
        data.Type = {
            labels: "Row",
            headers: "Header",
            "table-items": "TableItem",
        }[type];
    }

    if (data.References) {
        delete data.References;
    }

    if (!id || id === null || id === "undefined") {
        data.Id = null;
    } else {
        data.Id = id;
    }

    Object.keys(data).forEach((key) => {
        if (data[key] === "") {
            delete data[key];
        }
    });

    if (type === "tags") {
        const tagsData: tagsValidationType = {
            id: data.Id ?? null,
            label: data.Label ?? null,
            company: data.Company ?? null,
            requestGlobal: data.RequestGlobal ?? null,
            tagGroup: data.TagGroup ?? null,
            dateSpectrum: data.DateSpectrum ?? null,
            isLabel: data.IsLabel ?? null,
            isSetting: data.IsSetting ?? null,
            mergePresentation: data.MergePresentation ?? null,
            isFootnote: data.IsFootnote ?? null,
            isArea: data.IsArea ?? null,
            lang: data.Lang ?? null,
            periodId: data.PeriodId ?? null,
            customTaxonomyGroup: data.CustomTaxonomyGroup ?? null,
            dimensionValueReferences: data.DimensionValueReferences ?? null,
            hierarchyElementReferences: data.HierarchyElementReferences ?? null,
            extensionStructureReferences: data.ExtensionStructureReferences ?? null,
            taxonomies: data.Taxonomies ?? null,
        };
        return capitalizeKeys(tagsData);
    } else if (type === "headers") {
        const headerData: headerValidationType = {
            id: data.Id ?? null,
            label: data.Label ?? null,
            company: data.Company ?? null,
            requestGlobal: data.RequestGlobal ?? null,
            elementReference: data.ElementReference ?? null,
            tagGroup: data.TagGroup ?? null,
            dateSpectrum: data.DateSpectrum ?? null,
            invertSign: data.InvertSign ?? null,
            skipTagging: data.SkipTagging ?? null,
            isLabelFootNote: data.IsLabelFootnote ?? null,
            lang: data.Lang ?? null,
            ignoreInPresentation: data.IgnoreInPresentation ?? null,
            periodId: data.PeriodId ?? null,
            currencyCode: data.CurrencyCode ?? null,
            scale: data.Scale ?? null,
            valueLanguage: data.ValueLanguage ?? null,
            dateOverride: data.DateOverride ?? null,
            searchDirection: data.SearchDirection ?? null,
            dimensionValueReferences: data.DimensionValueReferences ?? null,
            taxonomies: data.Taxonomies ?? null,
            notes: data.Notes ?? null,
            overrideTypedMemberValueReference: data.OverrideTypedMemberValueReference ?? null,
            type: data.Type ?? null,
            table: data.Table ?? null,
            row: data.Row ?? null,
        };
        return capitalizeKeys(headerData);
    } else if (type === "labels") {
        const labelData: labelsValidationType = {
            id: data.Id ?? null,
            label: data.Label ?? null,
            company: data.Company ?? null,
            requestGlobal: data.RequestGlobal ?? null,
            elementReference: data.ElementReference ?? null,
            nameElement: data.NameElement ?? null,
            tagGroup: data.TagGroup ?? null,
            dateSpectrum: data.DateSpectrum ?? null,
            invertSign: data.InvertSign ?? null,
            lang: data.Lang ?? null,
            ignoreInPresentation: data.IgnoreInPresentation ?? null,
            periodId: data.PeriodId ?? null,
            currencyCode: data.CurrencyCode ?? null,
            scale: data.Scale ?? null,
            valueLanguage: data.ValueLanguage ?? null,
            transformation: data.Transformation ?? null,
            before: data.Before ?? null,
            merge: data.Merge ?? null,
            allowHtml: data.AllowHtml ?? null,
            skipSpace: data.SkipSpace ?? null,
            dateOverride: data.DateOverride ?? null,
            dimensionValueReferences: data.DimensionValueReferences ?? null,
            additionalElementReferences: data.AdditionalElementReferences ?? null,
            taxonomies: data.Taxonomies ?? null,
            notes: data.Notes ?? null,
            overrideTypedMemberValueReference: data.OverrideTypedMemberValueReference ?? null,
            type: data.Type ?? null,
            table: data.Table ?? null,
            row: data.Row ?? null,
        };
        return capitalizeKeys(labelData);
    } else if (type === "dimensions") {
        const dimensionData: customDimensionsValidationType = {
            id: data.Id ?? null,
            name: data.Name ?? null,
            company: data.Company ?? null,
            requestGlobal: data.RequestGlobal ?? null,
            labels: data.Labels ?? null,
            taxonomies: data.Taxonomies ?? null,
        };
        return capitalizeKeys(dimensionData);
    }
    else if (type === "dimension-values") {
        const customDimensionData: customDimensionValueValidationType = {
            id: data.Id ?? null,
            dimension: data.Dimension ?? null,
            customMemberName: data.CustomMemberName ?? null,
            company: data.Company ?? null,
            requestGlobal: data.RequestGlobal ?? null,
            isTypedMember: data.IsTypedMember ?? null,
            isDefaultMember: data.IsDefaultMember ?? null,
            parentMember: data.ParentMember ?? null,
            childDimensionValues: data.ChildDimensionValues ?? null,
            taxonomies: data.Taxonomies ?? null,
            labels: data.Labels ?? null,
        };
        return capitalizeKeys(customDimensionData);
    } else if (type === "elements") {
        const extensionData: extensionElementsValidationType = {
            id: data.Id ?? null,
            name: data.Name ?? null,
            company: data.Company ?? null,
            requestGlobal: data.RequestGlobal ?? null,
            parserDataTypeId: data.ParserDataTypeId ?? null,
            isInstant: data.IsInstant ?? null,
            invertSign: data.InvertSign ?? null,
            isCredit: data.IsCredit ?? null,
            isAbstract: data.IsAbstract ?? null,
            parentReference: data.ParentReference ?? null,
            childElementReferences: data.ChildElementReferences ?? null,
            labels: data.Labels ?? null,
            documentationLabels: data.DocumentationLabels ?? null,
            taxonomies: data.Taxonomies ?? null,
        };
        return capitalizeKeys(extensionData);
    } else if (type === "settings") {
        const settingsData: settingsValidationType = {
            id: data.Id ?? null,
            settingsName: data.SettingName ?? null,
            required: data.Required ?? null,
            labels: data.Labels ?? null,
            taxonomies: data.Taxonomies ?? null,
        };
        return capitalizeKeys(settingsData);
    } else if (type === "contexts") {
        const contextSettingsData: contextSettingsValidationType = {
            id: data.Id ?? null,
            context: data.Context ?? null,
            period: data.Period ?? null,
            dimensionValueReferences: data.DimensionValueReferences ?? null,
            taxonomies: data.Taxonomies ?? null,
        };
        return capitalizeKeys(contextSettingsData);
    } else {
        return null;
    }
}