import { ListItemText, Autocomplete, TextField } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from "react";
import { generalGet, generalPost } from "../../api/general";

export const EndpointLabel = ({
    label,
    value,
    endpoint,
    onValueChange,
    placeholder,
    disabled,
    setSelectedValue,
    urlType = "POST",
}: {
    label: string | undefined;
    value: string | null;
    endpoint: string;
    onValueChange?: (event: string) => void;
    setSelectedValue?: (value: string | null) => void;
    placeholder?: string;
    disabled?: boolean;
    urlType?: "POST" | "GET";
}) => {
    const [optionsState, setOptionsState] = useState<string[]>([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                let data;
                if (urlType === "POST") {
                    const response = await generalPost<string[]>(endpoint, []);
                    data = response.data;
                } else if (urlType === "GET") {
                    const response = await generalGet<string[]>(endpoint);
                    data = response.data;
                }
                setOptionsState(data || []);
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        };

        fetchOptions();
    }, [endpoint, urlType]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <Autocomplete
                    options={optionsState}
                    getOptionLabel={(option) => option}
                    value={value}
                    onChange={(event, newValue) => {
                        if (onValueChange) {
                            onValueChange(newValue || "");
                        }
                    }}
                    onBlur={(event) => {
                        const inputValue = (event.target as HTMLInputElement).value;
                        const matchingOptions = optionsState.filter(option =>
                            option.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        if (matchingOptions.length === 1) {
                            onValueChange?.(matchingOptions[0]);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};