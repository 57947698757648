import {
  Typography,
  Button,
  Pagination,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box, Container } from "@mui/system";
import { ObjectsWrapper } from "./objectsWrapper";
import {
  Apifilters,
  ISelectTemplate,
  navBarProps,
} from "./../../api/customObjectsTypes";
import DataTable from "./../dataTable";
import { useEffect, useRef, useState } from "react";
import { t } from "@lingui/core/macro";
import CustomObjectsDialog from "./customObjectsDialog";
import { usePostQuery } from "../../hooks/usePostQuery";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import AlertSnackbar from "../alertSnackbar";
import { getColumns } from "./columnsMapping";


interface CutsomTableProps {
  headerTitle: string;
  url: string;
  selectTemplate: ISelectTemplate;
  onUpdate: (
    updateStatus: "error" | "warning" | "info" | "success",
    reason?: string | undefined
  ) => void;
  taxonomyChanged: boolean;
  setTaxonomyChanged: (value: boolean) => void;
}

export const CustomObjectsPageComponent = <T extends { result: U[]; totalCount: number, filters: Apifilters[] }, U extends { id: string; entity?: string; entityReference?: { id: string } }>({
  headerTitle,
  url,
  selectTemplate,
  taxonomyChanged,
  setTaxonomyChanged,
}: CutsomTableProps) => {

  const getQuerySelector = (selectTemplate: ISelectTemplate) => {
    switch (selectTemplate) {
      case "tags":
        return {
          filters: [
            { column: "label", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "tagGroup", ColumnType: 1, filterType: 0, sortDescending: false },
            { column: "company_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "dateSpectrum", ColumnType: 1, filterType: 0, sortDescending: false },
            { column: "isLabel", ColumnType: 1, filterType: 0, sortDescending: false },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "dimensionValueReferences", ColumnType: 1, filterType: 1, sortDescending: false },
          ],
          page: 1,
          pageCount: 20,
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "header":
      case "labels":
      case "tableItems":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "searchLabel", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "elementReference_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "company_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "tagGroup", ColumnType: 1, filterType: 0, sortDescending: false },
            { column: "dateSpectrum", ColumnType: 1, filterType: 0, sortDescending: false },
            { column: "lang", ColumnType: 1, filterType: 0, sortDescending: false },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "dimensionValueReferences_dimensionValue_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "additionalElementReferences", ColumnType: 1, filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "customDimensions":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "valueName", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "company_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "dimensionValue":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "dimension_name", filterType: 1, sortDescending: false },
            { column: "valueName", filterType: 1, sortDescending: false },
            { column: "isTypedMember", filterType: 1, sortDescending: false },
            { column: "isDefaultMember", filterType: 1, sortDescending: false },
            { column: "company_name", filterType: 1, sortDescending: false },
            { column: "taxonomies", filterType: 1, sort: true, sortDescending: false },
            { column: "parentMember_name", filterType: 1, sortDescending: false },
            { column: "childDimensionValues", filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "extensionElements":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "company_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "taxonomies", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "parentReference_name", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "childElementReferences", ColumnType: 1, filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "settings":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "settingName", filterType: 0, sortDescending: false },
            { column: "required", columnType: "bool", value: null, filterType: 0, sortDescending: false },
            { column: "labels", filterType: 1, sort: false, sortDescending: false },
            { column: "taxonomies", filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "contextSettings":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "context", filterType: 1, sortDescending: false },
            { column: "period", filterType: 1, sortDescending: false },
            { column: "taxonomies", filterType: 1, sortDescending: false },
            { column: "dimensionValueReferences", filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "taxonomyDimensionValues":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "dimension", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "value", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "isTypedMember", ColumnType: 1, filterType: 1, sortDescending: false },
            { column: "isDefault", ColumnType: 1, filterType: 1, sortDescending: false },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "globalRequests":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "entityReference_name", filterType: 1 },
            { column: "entity", value: null, filterType: 0 },
            { column: "company_name", filterType: 1 },
            { column: "requester_name", value: null, filterType: 0 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "historyLog":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "reference_name", filterType: 1 },
            { column: "user_name", filterType: 1 },
            { column: "type", filterType: 0 },
            { column: "action", filterType: 0 },
            { column: "timeStamp", filterType: 1, sort: true, sortDescending: true },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      case "review":
        return {
          page: 1,
          pageCount: 20,
          filters: [
            { column: "reference_name", filterType: 1 },
            { column: "company_name", filterType: 1 },
            { column: "user_name", filterType: 1 },
            { column: "type", filterType: 0 },
            { column: "action", filterType: 0 },
            { column: "timeStamp", filterType: 1, sort: true, sortDescending: true },
            { column: "reviewed", filterType: 1 },
          ],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
      default:
        return {
          page: 1,
          pageCount: 20,
          filters: [],
          taxonomyIds: getSelectedTaxonomyIds(),
        }
    }
  }

  const [cacheBuster] = useState(crypto.randomUUID());
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogSelector, setDialogSelector] = useState<"tags" | "labels" | "header" | "extensionElements" | "customDimensions" | "dimensionValue" | undefined>(undefined);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [columns, setColumns] = useState<navBarProps[]>([]);
  const [req, setReq] = useState(() => getQuerySelector(selectTemplate));
  const [shouldFetch, setShouldFetch] = useState(true);
  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  useEffect(() => {
    if (!req) {
      setReq(getQuerySelector(selectTemplate));
    }
  }, [cacheBuster, selectTemplate, req]);

  useEffect(() => {
    const hasSortDescendingChanged = req.filters.some((filter: Apifilters) => filter.sortDescending !== undefined);
    if (hasSortDescendingChanged) {
      setReq((prevReq: any) => {
        const newFilters = prevReq.filters.map((filter: Apifilters) => ({
          ...filter,
          sortDescending: filter.sortDescending,
        }));
        const isSame = JSON.stringify(prevReq.filters) === JSON.stringify(newFilters);
        if (isSame) return prevReq;
        return {
          ...prevReq,
          filters: newFilters,
          page: 1,
        };
      });
    }
  }, [req.filters]);

  useEffect(() => {
    if (taxonomyChanged) {
      setReq((prevReq: any) => {
        return {
          ...prevReq,
          taxonomyIds: getSelectedTaxonomyIds(),
          page: 1,
        };
      });
      setTaxonomyChanged(false);
      setShouldFetch(true);
    }
  }, [taxonomyChanged]);

  const { data, error, loading } = usePostQuery<T>(
    `api/custom-objects/${url}/search?cb=${cacheBuster}`,
    req,
    !shouldFetch,
  );

  useEffect(() => {
    if (shouldFetch) {
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  const previousFiltersRef = useRef<Apifilters[] | null>(null);

  useEffect(() => {
    if (data?.filters) {
      const previousFilters = previousFiltersRef.current;
      const isDifferent = JSON.stringify(previousFilters) !== JSON.stringify(data.filters);

      if (isDifferent) {
        setReq((prevReq) => {
          const newFilters = prevReq.filters.map((filter) => {
            const matchingFilter = data.filters.find((f) => f.column === filter.column);
            return matchingFilter ? { ...filter, ...matchingFilter } : filter;
          });
          return { ...prevReq, filters: newFilters, page: 1 };
        });

        previousFiltersRef.current = data.filters;
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = error.response?.data as { isTranslated?: boolean; exceptions?: string[] };
      if (errorData?.isTranslated && errorData?.exceptions) {
        setAlertMessage({ message: errorData.exceptions[0], severity: "error" });
      } else {
        setAlertMessage({ message: "", severity: "error" });
      }
    }
  }, [error]);

  const handleSetFilter = (columnId: string, value?: string | boolean | number | null, sortDescending?: boolean, enterPressed?: boolean) => {
    if (columnId === "page") {
      setReq((prevReq: any) => {
        return {
          ...prevReq,
          page: value,
        };
      });
    } else {

      setReq((prevReq: any) => {
        return {
          ...prevReq,
          filters: prevReq.filters.map((filter: any) => {
            if (filter.column === columnId) {
              return { ...filter, value: value === "null" ? null : value === "empty" ? "" : value, sortDescending };
            }
            return filter;
          }),
        };
      });
    }
    if (enterPressed) {
      setShouldFetch(true);
    }
  };

  useEffect(() => {
    const columns = getColumns(selectTemplate) as any;
    setColumns(columns);
  }, [selectTemplate]);

  const handlecloseDialog = (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => {
    setOpenDialog(false);
    setSelectedId(undefined);
    if (updateStatus === "success" || updateStatus === "error") {
      setAlertMessage({
        message: reason || t`Something went wrong`,
        severity: updateStatus,
      });
    }
    if (updateStatus === "success") {
      setReq((prevReq) => ({
        ...prevReq,
      }));
      setShouldFetch(true);
    }
  };

  return (
    <ObjectsWrapper>
      <Grid container maxWidth={"false"} alignItems="center" sx={{ p: 2 }}>
        <Grid size={6}>
          <Typography variant="h5" fontWeight={"bold"} sx={{ pl: 2, pb: 2, pt: 2 }}>
            {headerTitle}
          </Typography>
        </Grid>
        <Grid size={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Typography
            sx={{
              display: "inline-block",
              pb: 0.5,
              pt: 0.5,
              pl: 1,
              pr: 4,
              borderRadius: 2,
            }}
          >
            {t`Total:`}
            <span style={{ paddingLeft: 5 }}>{data ? data.totalCount : 0}</span>
          </Typography>
          {!(selectTemplate === "globalRequests" || selectTemplate === "historyLog") && (
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              {t`Add`}
            </Button>
          )}
        </Grid>
      </Grid>
      <Container maxWidth={false} sx={{ p: 0, m: 0 }}>
        <DataTable<U>
          columns={columns}
          data={data?.result || []}
          filters={req.filters || []}
          onClickRow={(row) => {
            if (selectTemplate === "globalRequests") {
              const rowObj = data?.result.find((r) => r.id === row);
              setDialogSelector(
                rowObj?.entity === "CustomLabel" ?
                  "labels" : rowObj?.entity === "CustomTags" ?
                    "tags" : rowObj?.entity === "ExtensionElement" ?
                      "extensionElements" : rowObj?.entity === "ExtensionDimension" ?
                        "customDimensions" : rowObj?.entity === "CustomHeader" ?
                          "header" : rowObj?.entity === "CustomDimensionValue" ?
                            "dimensionValue" : undefined);
              setSelectedId(rowObj?.entityReference?.id);
            } else {
              setSelectedId(row);
            }
          }}
          noDataMessage={t`No data found`}
          setFilter={handleSetFilter}
          renderTableHeader={true}
          loading={loading}
          verticalAlign="top"
        />
        {data && data.totalCount > 20 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
            <Pagination
              count={Math.ceil(data.totalCount / 20)}
              page={req.page}
              onChange={(event, value) => handleSetFilter("page", value, undefined, true)}
              color="primary"
            />
          </Box>
        )}
      </Container>

      {(selectedId !== undefined || openDialog) && (
        <CustomObjectsDialog
          id={selectedId}
          url={
            dialogSelector
              ? dialogSelector === "header"
                ? "headers"
                : dialogSelector === "extensionElements"
                  ? "elements"
                  : dialogSelector === "customDimensions"
                    ? "dimensions"
                    : dialogSelector === "dimensionValue"
                      ? "dimension-values"
                      : dialogSelector === "tags"
                        ? "tags"
                        : dialogSelector === "labels"
                          ? "labels"
                          : url
              : url
          }
          open={selectedId !== undefined || openDialog}
          onClose={handlecloseDialog}
          selectTemplate={dialogSelector || selectTemplate}
        //globalRequestType={dialogSelector}
        />
      )}

      {alertMessage &&
        <AlertSnackbar
          alertMessage={alertMessage}
          updateAlertStatus={setAlertMessage}
        />
      }
    </ObjectsWrapper>
  );
};
