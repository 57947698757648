import { t, Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ApiOrganization, ApiSubscription } from "../../api/types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {
  addScheme,
  removeScheme,
  toggleOrganizationActivation,
  updateOrganizationDetails,
} from "../../api/organizations";
import {
  getOrganizationCountryValidationError,
  getOrganizationLeiCodeValidationError,
  getOrganizationRegNumberValidationError,
} from "../../helpers/validateOrganization";

import { useParams } from "react-router-dom";
import OrganizationUsers from "../users/organizationUsers";
import { Loader } from "../loader";
import { ContainerWrapper } from "../containerWrapper";
import { ToggleSwitcher } from "../toggleSwitcher";
import { generalGet } from "../../api/general";
import { AxiosError } from "axios";
import { NewSubScriptionDialog } from "./subscriptions/newSubScriptionDialog";
import DataTable from "../dataTable";
import { SubscriptionColumns } from "./subscriptions/subscriptionsColumns";
import RemoveSubscriptionDialog from "./subscriptions/removeSubscriptionDialog";
import { setNestedObjectValue } from "../../helpers/getNestedObjectValue";
import AlertSnackbar from "../alertSnackbar";
export interface OrganizationDetailsDialogProps {
  open: boolean;
  id: string | undefined;
  onClose: (updateStatus: "none" | "success" | "error") => void;
}

export const OrganizationById = () => {
  let { id } = useParams();

  const [originalTitle, setOriginalTitle] = useState("");
  const [updating, setUpdating] = useState(false);
  const [showConsentDialog, setShowConsentDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState<
    | {
        message: string;
        severity: "error" | "warning" | "info" | "success" | undefined;
      }
    | undefined
  >(undefined);

  const [showNewSubscriptionDialog, setShowNewSubscriptionDialog] =
    useState(false);

  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());

  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [data, setData] = useState<ApiOrganization>();
  const [subscriptions, setSubScribtions] = useState<ApiSubscription[]>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [removeSubscriptionDialog, setRemoveSubscriptionDialog] =
    useState<boolean>();
  const [subScriptionId, setSubScriptionId] = useState<string>();

  interface ApiPermissionScheme {
    id: string;
    name: string;
    action?: "add" | "remove" | undefined;
    fromApi?: boolean;
  }

  const [allPermissionSchemes, setAllPermissionSchemes] = useState<
    ApiPermissionScheme[]
  >([]);

  const handleClosedImportDialog = (
    updateStatus: "none" | "success" | "error",
    reason: string | undefined
  ) => {
    setRemoveSubscriptionDialog(false);
    setSubScriptionId(undefined);
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    } else {
      if (updateStatus === "error" && reason) {
        setAlertMessage({
          message: reason || "",
          severity: updateStatus,
        });
      }
    }
  };

  useEffect(() => {
    async function getOrganizationSchemes() {
      try {
        const data = await generalGet<ApiOrganization>(
          `/api/organizations/${id}?cb=${cacheBuster}`
        );
        setData(data.data as ApiOrganization);
        const subscriptions = await generalGet<ApiSubscription[]>(
          `api/organizations/${id}/subscriptions/all`
        );
        setSubScribtions(subscriptions.data);
      } catch (error) {
        setError(error as AxiosError);
      } finally {
        setLoading(false);
      }
    }
    getOrganizationSchemes();
  }, [cacheBuster, id]);

  useEffect(() => {
    if (error) {
      const errorData = error?.response?.data as {
        IsTranslated?: boolean;
        Exceptions?: string[];
      };
      if (errorData?.IsTranslated === true && errorData?.Exceptions) {
        setAlertMessage({
          message: errorData.Exceptions[0],
          severity: "error",
        });
      }
    }
  }, [data, error]);

  const handleFieldValueChange = (
    key: keyof ApiOrganization | string | undefined,
    value: string | boolean
  ) => {
    if (key) {
      setValueChanged(true);
      if (!originalTitle) {
        setOriginalTitle(data?.name || "");
      }
      const updated = { ...data };
      setNestedObjectValue(updated, key, value);
      setData(updated as ApiOrganization);
    }
  };

  const handleToggleOrganizationActivation = async () => {
    setUpdating(true);
    try {
      await toggleOrganizationActivation(
        id as string,
        data?.isDeactiviated as boolean
      );
      setCacheBuster(crypto.randomUUID());
    } catch (ex: any) {
      setUpdating(false);
      setAlertMessage({
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`,
        severity: "error",
      });
    } finally {
      setShowConsentDialog(false);
      setUpdating(false);
    }
  };

  const handleSubmit = async () => {
    setUpdating(true);
    try {
      if (allPermissionSchemes.length > 0) {
        allPermissionSchemes.forEach((element) => {
          if (element.action === "add") {
            addScheme(id as string, element.id);
          } else if (element.action === "remove")
            removeScheme(id as string, element.id);
        });
      }

      if (data) {
        await updateOrganizationDetails(id as string, {
          name: data?.name,
          country: data?.country,
          leiCode: data?.leiCode || null,
          registrationNumber: data?.registrationNumber || null,
          isCredentialAuthDisallowed: data?.isCredentialAuthDisallowed,
          licensing: {
            hasConverter: data?.licensing.hasConverter,
            hasInspector: data?.licensing.hasInspector,
            onlyAllowVisualFile: data?.licensing.onlyAllowVisualFile,
            hasAllTaxonomies: data?.licensing.hasAllTaxonomies,
            hasSelfService: data?.licensing.hasSelfService,
          },
          SendSignUpEmail: data?.SendSignUpEmail,
          isMicrosoftAuthDisallowed: data?.isMicrosoftAuthDisallowed,
        });
        setCacheBuster(crypto.randomUUID());
      }
      if (allPermissionSchemes.length > 0) {
        allPermissionSchemes.forEach((element) => {
          if (element.action === "add" && !element.fromApi) {
            addScheme(id as string, element.id);
          } else if (element.action === "remove" && element.fromApi) {
            removeScheme(id as string, element.id);
          }
        });
      }
      setCacheBuster(crypto.randomUUID());
      setUpdating(false);
      setAlertMessage({
        message: t`Organization updated successfully`,
        severity: "success",
      });
    } catch (ex: any) {
      setUpdating(false);
      setAlertMessage({
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`,
        severity: "error",
      });
    }
  };

  return (
    <>
      <ContainerWrapper>
        <Container maxWidth={false}>
          {loading && <Loader height={530} />}
          {!loading && error && (
            <Alert severity="error">
              <Trans>
                Could not load organization details. Please try again later
              </Trans>
            </Alert>
          )}
          {!loading && !error && data && (
            <>
              <Paper sx={{ p: 5 }}>
                <Container
                  maxWidth={false}
                  sx={{
                    ml: -2,
                    mb: 10,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid container>
                    <Typography variant="h4">
                      {originalTitle || data?.name}
                    </Typography>
                  </Grid>
                </Container>
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`Id`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText
                          title={data?.id}
                          secondary={data?.id}
                          secondaryTypographyProps={{
                            noWrap: true,
                            whiteSpace: "initial",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`Name`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText>
                          <TextField
                            required
                            sx={{ width: "90%" }}
                            variant="standard"
                            value={data?.name}
                            error={
                              data?.name === "" ||
                              data?.name === undefined ||
                              data?.name === null
                            }
                            onChange={(e) =>
                              handleFieldValueChange(
                                "name",
                                e.currentTarget.value
                              )
                            }
                          />
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`Country`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText>
                          <TextField
                            required
                            error={
                              getOrganizationCountryValidationError(
                                data?.country
                              ).length > 0
                            }
                            helperText={getOrganizationCountryValidationError(
                              data?.country
                            )}
                            sx={{ width: "90%" }}
                            variant="standard"
                            value={data?.country}
                            onChange={(e) =>
                              handleFieldValueChange(
                                "country",
                                e.currentTarget.value
                              )
                            }
                          />
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`Domain`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText
                          title={data?.domain || ""}
                          secondary={data?.domain}
                          secondaryTypographyProps={{
                            noWrap: true,
                            whiteSpace: "initial",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`Registration Number`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText>
                          <TextField
                            required
                            error={
                              getOrganizationRegNumberValidationError(
                                data?.registrationNumber || ""
                              ).length > 0
                            }
                            helperText={getOrganizationRegNumberValidationError(
                              data?.registrationNumber || ""
                            )}
                            sx={{ width: "90%" }}
                            variant="standard"
                            value={data?.registrationNumber}
                            onChange={(e) =>
                              handleFieldValueChange(
                                "registrationNumber",
                                e.currentTarget.value
                              )
                            }
                          />
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`LEI Code`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ListItemText>
                          <TextField
                            error={
                              getOrganizationLeiCodeValidationError(
                                data?.leiCode || ""
                              ).length > 0
                            }
                            helperText={getOrganizationLeiCodeValidationError(
                              data?.leiCode || ""
                            )}
                            sx={{ width: "90%" }}
                            variant="standard"
                            value={data?.leiCode}
                            onChange={(e) =>
                              handleFieldValueChange(
                                "leiCode",
                                e.currentTarget.value
                              )
                            }
                          />
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Container
                    maxWidth={false}
                    sx={{
                      mt: 10,
                      mb: 5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">{t`License`}</Typography>
                  </Container>
                  <Grid item xs={6} md={3}>
                    <ToggleSwitcher
                      title={t`Credentials`}
                      subText={t`Allow users to login with credentials`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "isCredentialAuthDisallowed",
                          !e.target.checked
                        )
                      }
                      currentvalue={!data?.isCredentialAuthDisallowed}
                    />
                  </Grid>
                  <Grid item xs={8} md={3}>
                    <ToggleSwitcher
                      title={t`Microsoft Login`}
                      subText={t`Allow users to login with Microsoft login`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "isMicrosoftAuthDisallowed",
                          !e.target.checked
                        )
                      }
                      currentvalue={!data?.isMicrosoftAuthDisallowed}
                    />
                  </Grid>
                  <Grid item xs={8} md={3}></Grid>
                  <Grid item xs={8} md={3}></Grid>
                  <Grid item xs={6} md={3}>
                    <ToggleSwitcher
                      title={t`Converter`}
                      subText={t`Allow converter to be used`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "licensing.hasConverter",
                          e.target.checked
                        )
                      }
                      currentvalue={data?.licensing?.hasConverter}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <ToggleSwitcher
                      title={t`Inspector`}
                      subText={t`Allow inspector to be used`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "licensing.hasInspector",
                          e.target.checked
                        )
                      }
                      currentvalue={data?.licensing?.hasInspector}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <ToggleSwitcher
                      title={t`Visual File`}
                      subText={t`Allow only visual files to be imported`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "licensing.onlyAllowVisualFile",
                          e.target.checked
                        )
                      }
                      currentvalue={data?.licensing?.onlyAllowVisualFile}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <ToggleSwitcher
                      title={t`Has all taxonomies`}
                      subText={t`Allow all taxonomies to be used`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "licensing.hasAllTaxonomies",
                          e.target.checked
                        )
                      }
                      currentvalue={data?.licensing.hasAllTaxonomies}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <ToggleSwitcher
                      title={t`Self Service Enabled`}
                      subText={t`This org can use the self service system`}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "licensing.hasSelfService",
                          e.target.checked
                        )
                      }
                      currentvalue={data?.licensing.hasSelfService}
                    />
                  </Grid>
                  <Grid item xs={8} md={3}></Grid>
                  {allPermissionSchemes.length > 0 && (
                    <Container
                      maxWidth={false}
                      sx={{
                        mt: 10,
                        mb: 5,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h4">{t`Permission Scheme`}</Typography>
                    </Container>
                  )}
                  {allPermissionSchemes.length > 0 && (
                    <Grid
                      item
                      xs={6}
                      md={3}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      {allPermissionSchemes.length > 0 &&
                        allPermissionSchemes.map((scheme) => (
                          <Paper elevation={4} sx={{ mr: 5, p: 1 }}>
                            <Grid
                              minWidth={250}
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Grid item xs={12} md={8} pl={2}>
                                <Typography variant="subtitle1">
                                  {scheme.name}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                container
                                justifyContent="flex-end"
                              >
                                <Switch
                                  onChange={(e) => {
                                    setAllPermissionSchemes(
                                      allPermissionSchemes.map((s) =>
                                        s.id === scheme.id
                                          ? {
                                              ...s,
                                              action: e.target.checked
                                                ? "add"
                                                : "remove",
                                            }
                                          : s
                                      )
                                    );
                                  }}
                                  checked={
                                    scheme.action === "add" ||
                                    scheme.action === undefined
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        ))}
                    </Grid>
                  )}
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {data?.isDeactiviated && (
                      <Button
                        size="large"
                        sx={{ mt: 5, width: 250 }}
                        variant="outlined"
                        onClick={() => setShowConsentDialog(true)}
                        disabled={updating}
                      >
                        <CheckIcon sx={{ fill: "green", pr: 0.5 }} />
                        Activate
                      </Button>
                    )}
                    {!data?.isDeactiviated && (
                      <Button
                        size="large"
                        sx={{
                          mt: 5,
                          width: 250,
                          backgroundColor: "white",
                          color: "black",
                          "&:hover": {
                            background: "#e6e6e6",
                          },
                        }}
                        variant="contained"
                        onClick={() => setShowConsentDialog(true)}
                        disabled={updating}
                      >
                        <CloseIcon
                          sx={{
                            fill: "red",
                            pr: 0.5,
                          }}
                        />
                        {t`DeActivate`}
                      </Button>
                    )}
                    <Button
                      size="large"
                      sx={{ mt: 5, width: 250, ml: 2 }}
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!valueChanged}
                    >
                      {!updating && <Trans>Update</Trans>}
                      {updating && <CircularProgress size={25} />}
                    </Button>
                  </Stack>
                  <Container
                    maxWidth={false}
                    sx={
                      data?.licensing.hasAllTaxonomies
                        ? {
                            opacity: "0.3",
                            pointerEvents: "none",
                          }
                        : {}
                    }
                  >
                    <Container
                      maxWidth={false}
                      sx={{
                        mt: 10,
                        mb: 5,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h4">{t`Subscriptions`}</Typography>
                      <Button
                        variant="contained"
                        onClick={() => setShowNewSubscriptionDialog(true)}
                      >
                        {t`Create new subscription`}
                      </Button>
                    </Container>
                    {subscriptions && (
                      <DataTable<ApiSubscription>
                        data={subscriptions}
                        columns={SubscriptionColumns({
                          openDialog: setRemoveSubscriptionDialog,
                          setId: setSubScriptionId,
                        })}
                      />
                    )}
                  </Container>
                </Grid>
              </Paper>
            </>
          )}
          <Divider orientation="vertical" flexItem>
            {" "}
          </Divider>
          <OrganizationUsers />
        </Container>
        <RemoveSubscriptionDialog
          open={removeSubscriptionDialog as boolean}
          id={subScriptionId}
          onClose={handleClosedImportDialog}
        />

        <NewSubScriptionDialog
          open={showNewSubscriptionDialog}
          id={id}
          onClose={() => {
            setShowNewSubscriptionDialog(false);
            setCacheBuster(crypto.randomUUID());
          }}
        />
        <Dialog open={showConsentDialog}>
          <DialogTitle>{t`Are you sure?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans>You are about to</Trans>{" "}
              {data?.isDeactiviated ? t`activate` : t`deactivate`}{" "}
              <Trans>this organization</Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConsentDialog(false)}>{t`No`}</Button>
            <Button
              onClick={handleToggleOrganizationActivation}
            >{t`Yes`}</Button>
          </DialogActions>
        </Dialog>
      </ContainerWrapper>
      <AlertSnackbar
        alertMessage={alertMessage}
        updateAlertStatus={setAlertMessage}
      />
    </>
  );
};
