import { ListItemText, Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid2';
import { getLanguages } from "../../api/customObjects";

export const SearchSelectLabel = ({
    label,
    value,
    onValueChange,
    placeholder,
    disabled,
    abortApiCall,
}: {
    label: string | undefined;
    value: string | null;
    onValueChange?: (event: string) => void;
    placeholder?: string;
    disabled?: boolean;
    abortApiCall?: boolean;
}) => {
    const [options, setOptions] = useState<string[]>([]);
    useEffect(() => {
        if (abortApiCall) return;

        const fetchOptions = async () => {
            try {
                const response = await getLanguages();
                let fetchedOptions = response;
                if (label === "Value Language") {
                    fetchedOptions = ["Null", ...fetchedOptions];
                }
                setOptions(fetchedOptions);
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        };

        fetchOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [abortApiCall]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            {label && (
                <Grid size={2}>
                    <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                </Grid>
            )}
            <Grid size={10}>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => option}
                    value={value?.toString() || ""}
                    onChange={(event, newValue) => {
                        if (onValueChange) {
                            onValueChange(newValue || "");
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        const matchingOptions = options.filter(option => option.toLowerCase().includes(newInputValue.toLowerCase()));
                        if (matchingOptions.length === 1) {
                            if (onValueChange) {
                                onValueChange(matchingOptions[0]);
                            }
                        }
                    }}
                    onBlur={(event) => {
                        const inputValue = (event.target as HTMLInputElement).value;
                        const matchingOptions = options.filter(option =>
                            option.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        if (matchingOptions.length === 1) {
                            onValueChange?.(matchingOptions[0]);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}