import NavbarCustomObjects from "../navbarCustomObjects";
import { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { t } from "@lingui/macro";
import { Snackbar, Alert, Container } from "@mui/material";
import { CustomObjectsPageComponent } from "./customObjectsPageComponent";
import {
  cContextSettingsColumns,
  cContextSettingsDetails,
  cCustomDimensionsColumns,
  cCustomDimensionsDetails,
  cCustomDimensionValueColumns,
  cCustomDimensionValueDetails,
  cExtensionElementsColumns,
  cExtensionElementsDetails,
  cGlobalRequestsColumns,
  cGlobalRequestsDetails,
  cHeaderColumns,
  cHeaderDetails,
  cLabelColumns,
  cLabelsResult,
  cSettingsColumns,
  cSettingsDetails,
  cTagColumns,
  cTagDetails
} from "../../api/customObjectsTypes";
import { ConsistencyCheck } from "./consistencyCheck";

const CustomObjects = () => {

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [url, setUrl] = useState<string>("");

  const [taxonomyChanged, setTaxonomyChanged] = useState(false);

  const [alertMessage, setAlertMessage] = useState<
    {
      message: string | undefined;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  useEffect(() => {
    if (path !== url) {
      setTimeout(() => {
      }, 1500);
      setUrl(path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleAlertUpdate = (message: string | undefined, severity: "error" | "warning" | "info" | "success" | undefined) => {
    setAlertMessage({ message, severity });
  };

  if (!path) {
    return <Navigate to="/customobjects/tags" replace />;
  }

  const handleClosingAlert = () => {
    /* sleep for 1 second before setting undefined */
    setTimeout(() => {
      setAlertMessage(undefined);
    }, 1000);
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        mt: 15,
        mb: 5,
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <NavbarCustomObjects
        setTaxonomyChanged={setTaxonomyChanged}
        taxonomyChanged={taxonomyChanged}
      />
      {
        path === "tags" && (
          <CustomObjectsPageComponent<cTagColumns, cTagDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            url="tags"
            headerTitle={t`Tags`}
            selectTemplate="tags"
            onUpdate={(updateStatus, reason) => {
              handleAlertUpdate(reason, updateStatus);
            }}
          />

        )
      }
      {
        path === "headers" && (
          <CustomObjectsPageComponent<cHeaderColumns, cHeaderDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            url="headers"
            headerTitle={t`Headers`}
            selectTemplate="header"
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "labels" && (
          <CustomObjectsPageComponent<cLabelColumns, cLabelsResult>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="labels"
            url="labels"
            headerTitle={t`Labels`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "tableitems" && (
          <CustomObjectsPageComponent<cLabelColumns, cLabelsResult>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="tableItems"
            url="table-items"
            headerTitle={t`Table Items`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "dimensions" && (
          <CustomObjectsPageComponent<cCustomDimensionsColumns, cCustomDimensionsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="customDimensions"
            url="dimensions"
            headerTitle={t`Custom Dimensions`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "dimensionvalues" && (
          <CustomObjectsPageComponent<cCustomDimensionValueColumns, cCustomDimensionValueDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="dimensionValue"
            url="dimension-values"
            headerTitle={t`Dimension Values`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "extensionelements" && (
          <CustomObjectsPageComponent<cExtensionElementsColumns, cExtensionElementsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="extensionElements"
            url="elements"
            headerTitle={t`Extension Elements`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "settings" && (
          <CustomObjectsPageComponent<cSettingsColumns, cSettingsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="settings"
            url="settings"
            headerTitle={t`Settings`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "contextsettings" && (
          <CustomObjectsPageComponent<cContextSettingsColumns, cContextSettingsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="contextSettings"
            url="contexts"
            headerTitle={t`Context Settings`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "globalrequests" && (
          <CustomObjectsPageComponent<cGlobalRequestsColumns, cGlobalRequestsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="globalRequests"
            url="global-requests"
            headerTitle={t`Global Requests`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "consistencycheck" && (
          <ConsistencyCheck />
        )
      }
      {
        path === "historylog" && (
          <CustomObjectsPageComponent<cGlobalRequestsColumns, cGlobalRequestsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="historyLog"
            url="history-log"
            headerTitle={t`History Log`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "review" && (
          <CustomObjectsPageComponent<cGlobalRequestsColumns, cGlobalRequestsDetails>
            taxonomyChanged={taxonomyChanged}
            setTaxonomyChanged={setTaxonomyChanged}
            selectTemplate="review"
            url="review-list/for-review"
            headerTitle={t`Review`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      <Snackbar
        open={alertMessage !== undefined}
        autoHideDuration={3000}
        onClose={handleClosingAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertMessage?.severity}>
          {alertMessage?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CustomObjects;
