import { ListItemText, Autocomplete, TextField, debounce } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState, useCallback } from "react";
import { DimensionValue, ElementReference, pageSelector } from "../../api/customObjectsTypes";
import AlertSnackbar from "../alertSnackbar";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import { usePostQuery } from "../../hooks/usePostQuery";
import { ElementDetailedView } from "./elementDetailedView";

export const ElementLabel = ({
    label,
    selectedValue,
    setSelectedValue,
    value,
    onValueChange,
    preDefinedOptions,
    placeholder,
    disabled,
    includeCustom = true,
    page = "normal",
    isAlternate,
    dimensionName,
}: {
    label: string | undefined;
    preDefinedOptions?: any[];
    selectedValue: any | null;
    setSelectedValue: (value: any | null) => void;
    value?: ElementReference;
    onValueChange?: (event: any) => void;
    placeholder?: string;
    disabled?: boolean;
    includeCustom?: boolean | null;
    page?: pageSelector;
    isAlternate?: "alternateElement" | null;
    dimensionName?: string;
}) => {

    const [query, setQuery] = useState("");
    const [optionsState, setOptionsState] = useState<ElementReference[]>(preDefinedOptions ?? []);
    const [cacheBuster] = useState(crypto.randomUUID());
    const [abortSignal, setAbortSignal] = useState(true);
    const [req, setReq] = useState({
        searchString: query,
        pageCount: 20,
        taxonomyIds: getSelectedTaxonomyIds(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleQueryChange = useCallback(debounce((newQuery: string) => {
        setReq({
            searchString: newQuery,
            pageCount: 20,
            taxonomyIds: getSelectedTaxonomyIds(),
        });
        setAbortSignal(false);
    }, 500), []);

    useEffect(() => {
        if (query.length > 0) {
            handleQueryChange(query);
        }
    }, [query, handleQueryChange]);

    const { data, loading, error } = usePostQuery<ElementReference[]>(
        isAlternate === "alternateElement" ? `/api/custom-objects/dimension-value-references/dimensions/${dimensionName}/members/search-by-name?cb=${cacheBuster}${includeCustom !== null ? `&includeCustom=${includeCustom}` : ""}` :
            `/api/custom-objects/element-references/search?cb=${cacheBuster}${includeCustom !== null ? `&includeCustom=${includeCustom}` : ""}`,
        req,
        abortSignal
    );
    useEffect(() => {
        if (data) {
            setOptionsState(data as ElementReference[]);
        }
    }, [data]);
    const sortedOptions = optionsState.sort((a, b) => {
        const namespaceA = a.taxonomyElement?.namespaceAbbreviation || "";
        const namespaceB = b.taxonomyElement?.namespaceAbbreviation || "";

        if (namespaceA === "ifrs-full" && namespaceB !== "ifrs-full") {
            return -1;
        }
        if (namespaceA !== "ifrs-full" && namespaceB === "ifrs-full") {
            return 1;
        }
        return 0;
    });

    return (
        <Grid container spacing={0} sx={{ mb: 2 }} maxWidth={"false"}>
            {error &&
                <AlertSnackbar
                    alertMessage={{ message: error.message, severity: "error" }}
                    updateAlertStatus={() => { }}
                />
            }
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <Autocomplete
                    forcePopupIcon={false}
                    options={sortedOptions}
                    getOptionLabel={(option) => option?.name || option?.extension?.name || option?.name || ""}
                    filterOptions={(options) => options}
                    renderOption={(props, option, state) => {
                        const { key, ...restProps } = props;
                        const isEven = state.index % 2 === 0;
                        return (
                            <li key={key} {...restProps}
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    listStyleType: "none",
                                }}
                            >
                                {option && <ElementDetailedView element={option} isEven={isEven} page={page} />}
                            </li>
                        );
                    }}
                    loading={loading}
                    value={selectedValue || null}
                    onChange={(_, newValue) => {
                        setSelectedValue(newValue || null);
                        if (onValueChange) {
                            onValueChange(newValue as DimensionValue | ElementReference);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%", fontSize: 14 }}
                            variant="standard"
                            disabled={disabled}
                            onChange={(event) => setQuery(event.target.value)}
                        /* onBlur={(event) => {
                            const inputValue = (event.target as HTMLInputElement).value;
                            const matchingOptions = optionsState.filter(option =>
                                option.name.toLowerCase().includes(inputValue.toLowerCase())
                            );
                            if (matchingOptions.length === 1) {
                                setSelectedValue(matchingOptions[0]);
                                onValueChange?.(matchingOptions[0]);
                            } else if (inputValue === "") {
                                setSelectedValue(null);
                                onValueChange?.(null);
                            }
                        }} */
                        />
                    )}
                />
                {selectedValue && <ElementDetailedView isOption={selectedValue} element={selectedValue} isEven={optionsState.indexOf(selectedValue) % 2 === 0} page={page} />}
            </Grid>
        </Grid>
    );
};
