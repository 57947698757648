import { t } from "@lingui/core/macro";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ListItemText,
    TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import LabeledTextField from "../labeledTextField/labeledTextField";
import { gridSelectionTypes } from "./genericGridTable";
import { DimensionValue, DimensionValueReference } from "../../api/customObjectsTypes";

const initialDimensionValue: DimensionValue = {};

export const DialogCustomDimensionValue = ({
    open,
    onClose,
    onSave,
    type,
    dimensionValueToEdit,
    dimensionName,
}: {
    open: boolean;
    onClose: () => void;
    onSave: (dimensionValue: DimensionValueReference) => void;
    type: gridSelectionTypes;
    dimensionValueToEdit?: DimensionValueReference | null;
    dimensionName?: string
}) => {
    const [dimensionValue, setDimensionValue] = useState<DimensionValue>(dimensionValueToEdit || initialDimensionValue);
    useEffect(() => {
        if (dimensionValueToEdit) {
            setDimensionValue(dimensionValueToEdit);
        } else {
            setDimensionValue(initialDimensionValue);
        }
    }, [dimensionValueToEdit]);

    const handleFieldChange = (fieldPath: string, value: any) => {
        if (fieldPath === "onlyWhenConsolidated") {
            setDimensionValue((prev) => ({
                ...prev,
                [fieldPath]: value || null,
            }));
        } else if (fieldPath === "dimensionValue" || fieldPath === "element" || fieldPath === "childDimensionValues" || fieldPath === "dimensionValueReferences") {
            if (type === "headerDimensionValues") {
                setDimensionValue((prev) => ({
                    ...prev,
                    [fieldPath]: value,
                    typedMemberValue: value.typedMemberValue,
                    onlyWhenConsolidated: value.onlyWhenConsolidated,
                }));
            } else {
                setDimensionValue(value);
            }
        } else {
            setDimensionValue((prev) => ({
                ...prev,
                [fieldPath]: value,
            }));
        }
    };

    const handleSave = () => {
        onSave(dimensionValue);
        onClose();
    };

    const getDialogTitle = (): string => {
        const titles: Record<gridSelectionTypes, string> = {
            dimension: t`Dimension Value`,
            additionalElementReferences: t`Element Value`,
            extension: t`Extension Value`,
            extensionStructureReferences: t`Select Element`,
            onlyDimension: t`Add Dimension`,
            dimensionValueReferences: t`Dimension Value`,
            headerDimensionValues: t`Dimension Value`,
            labelDimensionValues: t`Dimension Value`,
            dimensionLabels: t`Dimension Label`,
            validators: t`Dimension Value`,
            childDimensionValues: t`Select Dimension`,
            childElementReferences: t`Select Element`,
            basicList: t`Select Element`,
            hierarchyElementReferences: t`Hierarchy Element`,
        };
        return titles[type] || (dimensionValueToEdit ? t`Edit Value` : t`New Value`);
    };

    const sharedFields = (type: gridSelectionTypes) => {
        switch (type) {
            case "dimensionValueReferences":
                return (
                    <>
                        <LabeledTextField
                            label={t`Dimension`}
                            fieldType="dimension"
                            value={dimensionValue?.valueName || ""}
                            onValueChange={(value) => handleFieldChange("dimensionValue", value as DimensionValueReference)}
                            includeCustom={true}
                        />
                        <Grid container spacing={0} sx={{ mb: 2 }}>
                            <Grid size={2}>
                                <ListItemText primary={t`Bind to Consolidated value`} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                            </Grid>
                            <Grid size={10}>
                                <TextField
                                    select
                                    autoComplete="off"
                                    sx={{ width: "100%" }}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    variant="standard"
                                    value={dimensionValue?.onlyWhenConsolidated === true ? "True" : dimensionValue?.onlyWhenConsolidated === false ? "False" : ""}
                                    onChange={(event) => {
                                        const selectedOption = event.target.value;
                                        const booleanValue = selectedOption === "True" ? true : selectedOption === "False" ? false : null;
                                        handleFieldChange("onlyWhenConsolidated", booleanValue);
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value="">
                                        {` `}
                                    </option>
                                    <option value="True">{t`True`}</option>
                                    <option value="False">{t`False`}</option>
                                </TextField>
                            </Grid>
                        </Grid>
                    </>
                );
            case "childElementReferences":
                return (
                    <LabeledTextField
                        label={t`Element`}
                        fieldType="element"
                        value={dimensionValue?.name || ""}
                        onValueChange={(value) => handleFieldChange("element", value as DimensionValue)}
                        includeCustom={false}
                        dimensionName={dimensionName}
                        isAlternate="alternateElement"
                    />
                );
            case "childDimensionValues":
                return (
                    <LabeledTextField
                        label={t`Dimension`}
                        fieldType="dimension"
                        value={dimensionValue?.name || ""}
                        onValueChange={(value) => handleFieldChange("dimensionValue", value as DimensionValue)}
                        includeCustom={true}
                        isAlternate="alternateElement"
                        dimensionName={dimensionName}
                    />
                );
            case "extensionStructureReferences":
            case "hierarchyElementReferences":
            case "additionalElementReferences":
                return (
                    <LabeledTextField
                        label={t`Element`}
                        fieldType="element"
                        value={dimensionValue?.name || ""}
                        onValueChange={(value) => handleFieldChange("element", value as DimensionValue)}
                        includeCustom={true}
                    />
                );
            case "headerDimensionValues":
                return (
                    <>
                        <LabeledTextField
                            label={t`Dimension Value`}
                            fieldType="dimension"
                            value={dimensionValue?.valueName || ""}
                            onValueChange={(value) => handleFieldChange("dimensionValue", value)}
                        />
                        <LabeledTextField
                            label={t`Typed Member Value`}
                            fieldType="text"
                            value={dimensionValue?.typedMemberValue || ""}
                            onValueChange={(value) => handleFieldChange("typedMemberValue", value)}
                        />
                        <Grid container spacing={0} sx={{ mb: 2 }}>
                            <Grid size={2}>
                                <ListItemText primary={t`Bind to Consolidated value`} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                            </Grid>
                            <Grid size={10}>
                                <TextField
                                    select
                                    autoComplete="off"
                                    sx={{ width: "100%" }}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    variant="standard"
                                    value={dimensionValue?.onlyWhenConsolidated === true ? "True" : dimensionValue?.onlyWhenConsolidated === false ? "False" : ""}
                                    onChange={(event) => {
                                        const selectedOption = event.target.value;
                                        const booleanValue = selectedOption === "True" ? true : selectedOption === "False" ? false : null;
                                        handleFieldChange("onlyWhenConsolidated", booleanValue);
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value="">
                                        {` `}
                                    </option>
                                    <option value="True">{t`True`}</option>
                                    <option value="False">{t`False`}</option>
                                </TextField>
                            </Grid>
                        </Grid>
                    </>
                );
            default:
                return (
                    <>
                        <LabeledTextField
                            label={t`Typed Member Value`}
                            fieldType="text"
                            value={dimensionValue?.valueName || ""}
                            onValueChange={(value) => handleFieldChange("valueName", value)}
                        />
                        <Grid container spacing={0} sx={{ mb: 2 }}>
                            <Grid size={2}>
                                <ListItemText primary={t`Bind to Consolidated value`} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
                            </Grid>
                            <Grid size={10}>
                                <TextField
                                    select
                                    autoComplete="off"
                                    sx={{ width: "100%" }}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    variant="standard"
                                    value={dimensionValue?.onlyWhenConsolidated === true ? "True" : dimensionValue?.onlyWhenConsolidated === false ? "False" : ""}
                                    onChange={(event) => {
                                        const selectedOption = event.target.value;
                                        const booleanValue = selectedOption === "True" ? true : selectedOption === "False" ? false : null;
                                        handleFieldChange("onlyWhenConsolidated", booleanValue);
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value="">
                                        {` `}
                                    </option>
                                    <option value="True">{t`True`}</option>
                                    <option value="False">{t`False`}</option>
                                </TextField>
                            </Grid>
                        </Grid>
                    </>
                );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
            sx={{
                maxWidth: window.innerWidth > 1920 ? '70vw' : '90vw',
                margin: 'auto',
                "& .MuiDialog-paper": { width: "100%" }
            }}
        >
            <DialogTitle>{getDialogTitle()}</DialogTitle>
            <DialogContent
                sx={{
                    minHeight: window.innerHeight > 1080 ? '40vh' : '60vh',
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: 2
                }}>
                {sharedFields(type)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t`Cancel`}</Button>
                <Button onClick={handleSave}>
                    {dimensionValueToEdit ? t`Save Changes` : t`Add`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
