import { Box, Typography, Divider, Dialog, DialogContent, DialogTitle, Tabs, Tab, Button, DialogActions } from "@mui/material";
import DataTable from "../../dataTable";
import { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import LabeledTextField from "../../labeledTextField/labeledTextField";

export const TaxonomyEntryPoints = () => {
    const [entryPointsData, setEntryPointsData] = useState<TaxonomyEntryPoint[]>([]);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [selectedData, setSelectedData] = useState<TaxonomyEntryPoint | null>(null);
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        if (selectedId) {
            const data = entryPointsData.find((entryPoint) => entryPoint.id === selectedId);
            setSelectedData(data || null);
        }
    }, [selectedId]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <Box sx={{ pt: 2, pb: 2 }}>
                <Typography variant="h6">{t`Entry Points`}</Typography>
                <Divider />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                {entryPointsData.length === 0 ? (
                    <Typography>No entry points found</Typography>
                ) : (
                    <DataTable<TaxonomyEntryPoint>
                        data={entryPointsData}
                        columns={[
                            { header: { label: "Schema Ref", id: "schemaRef" }, key: "schemaRef" },
                            {
                                header: { label: "Labels", id: "labels" }, key: "labels", valueFormatter: (row) => row.labels.map(
                                    (label) => `${label.text}`
                                ).join(", "),
                                shouldWrapVertically: true,
                            },
                        ]}
                        noWidth
                        onClickRow={(row) => setSelectedId(row)}
                    />
                )}
            </Box>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={selectedId !== null}
                onClose={() => setSelectedId(null)}
            >
                <DialogTitle>
                    {t`Edit Entry Point: ${selectedId ? selectedId : ""}`}
                </DialogTitle>
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="entry point tabs">
                    <Tab label={<span style={{ color: 'black' }}>Details</span>} />
                    <Tab label={<span style={{ color: 'black' }}>Taxonomy Group</span>} />
                </Tabs>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 2,
                        maxHeight: "85vh",
                    }}
                >
                    {currentTab === 0 && (
                        <>
                            <Box sx={{
                                diaply: "flex",
                                flexDirection: "column",
                                padding: 2,
                                width: "100%",
                            }}>
                                <Typography variant="h4" fontWeight={"bold"} sx={{ mb: 2 }}>Header</Typography>
                                <LabeledTextField
                                    label="Schema Ref"
                                    value={selectedData?.schemaRef}
                                    fieldType="text"
                                />
                                <LabeledTextField
                                    label="Target Namespace"
                                    value={selectedData?.schemaRef}
                                    fieldType="text"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const newEntryPoint: TaxonomyEntryPoint = {
                                            id: `new-${Date.now()}`,
                                            schemaRef: "",
                                            labels: [],
                                            taxonomyGroups: [],
                                            targetNamespace: null,
                                            taxonomyId: ""
                                        };
                                        setEntryPointsData([...entryPointsData, newEntryPoint]);
                                        setSelectedId(newEntryPoint.id);
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                            <DataTable<TaxonomyEntryPoint>
                                data={entryPointsData}
                                columns={[
                                    { header: { label: "Language", id: "lang" }, key: "lang" },
                                    {
                                        header: { label: "Labels", id: "labels" }, key: "labels", valueFormatter: (row) => row.labels.map(
                                            (label) => `${label.text}`
                                        ).join(", "),
                                        shouldWrapVertically: true,
                                    },
                                ]}
                                noWidth
                                onClickRow={(row) => setSelectedId(row)}
                            />
                        </>
                    )}
                    {currentTab === 1 && (
                        <DataTable<TaxonomyGroup>
                            onClickRow={() => { }}
                            data={selectedData?.taxonomyGroups || []}
                            columns={[
                                { header: { label: "Href", id: "href" }, key: "href" },
                                { header: { label: "Official URI", id: "officialUri" }, key: "officialUri" },
                                { header: { label: "Role ID", id: "roleId" }, key: "roleId" },
                                { header: { label: "Relative Path", id: "relativePath" }, key: "relativePath" },
                                { header: { label: "Is Custom", id: "isCustom" }, key: "isCustom" },
                            ]}
                            noWidth
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectedId(null)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => console.log("Save functionality to be implemented")} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

interface TaxonomyGroup {
    href: string;
    officialUri: string;
    roleId: string;
    relativePath: string;
    calculations: any[];
    isCustom: boolean;
    id: string;
}

interface TaxonomyEntryPoint {
    schemaRef: string;
    targetNamespace: string | null;
    taxonomyGroups: TaxonomyGroup[];
    taxonomyId: string;
    labels: {
        lang: string;
        text: string;
        role: string;
        id: string | null;
    }[];
    id: string;
}

