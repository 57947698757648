import { ListItemText, Autocomplete, TextField, debounce } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { cCompany } from "../../api/customObjectsTypes";
import Grid from '@mui/material/Grid2';
import { usePostQuery } from "../../hooks/usePostQuery";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import { t } from "@lingui/macro";

export const CompanyLabel = ({
    label,
    value,
    onValueChange,
    disabled,
    placeholder,
    setSelectedValue,
    selectedValue,
}: {
    label?: string | undefined,
    value?: string,
    onValueChange?: (event: any) => void;
    disabled?: boolean,
    placeholder?: string,
    setSelectedValue?: (value: cCompany | null) => void,
    selectedValue: cCompany | null,
}) => {
    const [query, setQuery] = useState<string>("");
    const [optionsState, setOptionsState] = useState<cCompany[]>([]);
    const [req, setReq] = useState({
        searchString: query,
        pageCount: 20,
        taxonomyIds: getSelectedTaxonomyIds(),
    });

    const handleQueryChange = useCallback(debounce((newQuery: string) => {
        setReq({
            searchString: newQuery,
            pageCount: 20,
            taxonomyIds: getSelectedTaxonomyIds(),
        });
    }, 500), []);

    useEffect(() => {
        if (query.length > 2) {
            handleQueryChange(query);
        }
    }, [query, handleQueryChange]);

    const { data, error, loading } = usePostQuery(
        `api/custom-objects/companies/search-by-name`,
        req
    );

    useEffect(() => {
        if (data) {
            const sortedData = (data as cCompany[]);
            setOptionsState(sortedData);
        }
    }, [data]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            {error && <div>{t`Error loading options`}</div>}
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <Autocomplete
                    options={optionsState}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options) => options}
                    renderOption={(props, option) => (
                        <li
                            {...props}>{option.name}</li>
                    )}
                    loading={loading}
                    noOptionsText={error ? "Error loading options" : "No options"}
                    value={
                        selectedValue !== null ? selectedValue as cCompany :
                            optionsState.find((option) => option.name === (typeof value === 'string' ? value : (value as any)?.name)) || null
                    }
                    onChange={(event, newValue) => {
                        if (onValueChange) {
                            onValueChange(newValue as cCompany);
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue === "") {
                            setSelectedValue && setSelectedValue(null);
                        }
                    }}
                    onBlur={(event) => {
                        const inputValue = (event.target as HTMLInputElement).value;
                        const matchingOptions = optionsState.filter(option =>
                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        if (matchingOptions.length === 1) {
                            setSelectedValue?.(matchingOptions[0]);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}