import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    IconButton,
    TableHead,
    Typography,
    Tooltip,
    Paper,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DimensionValueReference, expandedTaxonomyElement } from '../../api/customObjectsTypes';
import { t } from '@lingui/core/macro';
import { TABLE_CONFIG } from './customObjectsPreData';
import { useEffect, useState } from 'react';
import { DialogCustomDimensionValue } from './dialogCustomDimensionValue';

export type gridSelectionTypes =
    | "dimension"
    | "additionalElementReferences"
    | "extension"
    | "extensionStructureReferences"
    | "onlyDimension"
    | "dimensionValueReferences"
    | "headerDimensionValues"
    | "labelDimensionValues"
    | "dimensionLabels"
    | "validators"
    | "childDimensionValues"
    | "childElementReferences"
    | "basicList"
    | "hierarchyElementReferences";

export default function GenericGridTable({
    id,
    data,
    setData,
    type,
    isEdiTable = true,
    dimensionName,
}: {
    id: string;
    data: expandedTaxonomyElement;
    setData: (newData: expandedTaxonomyElement) => void;
    type: gridSelectionTypes;
    isEdiTable?: boolean;
    dimensionName?: string
}) {
    const [showCaseData, setShowCaseData] = useState<{ [key: number]: DimensionValueReference }>(
        Object.fromEntries(
            Object.entries(data)
                .filter(([_, item]) => typeof item === 'object' && item !== null)
                .map(([key, item]) => [Number(key), item as DimensionValueReference])
        )
    );

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) {
            setShowCaseData({});
            return;
        }

        const newShowCaseData = Object.fromEntries(
            Object.entries(data)
                .filter(([_, item]) => typeof item === 'object' && item !== null && Object.keys(item).length > 0)
                .map(([key, item]) => [Number(key), item as DimensionValueReference])
        );

        setShowCaseData(newShowCaseData);
    }, [id, type, data]);

    const [dialogState, setDialogState] = useState({
        open: false,
        currentDimensionValue: null as DimensionValueReference | null,
        isEditing: false,
    });

    const updateShowCaseData = (newData: { [key: number]: DimensionValueReference }) => {
        setShowCaseData(newData);
        setData({
            ...data,
            ...Object.fromEntries(Object.entries(newData).map(([key, value]) => [key, value])),
        });
    };

    const handleDialogClose = () => {
        setDialogState({ open: false, currentDimensionValue: null, isEditing: false });
    };

    const handleDialogOpen = (DimensionValueReference: DimensionValueReference | null, isEditing: boolean) => {
        setDialogState({ open: true, currentDimensionValue: DimensionValueReference, isEditing });
    };

    const handleAddOrEditDimensionValue = (DimensionValueReference: any) => {
        let updatedData = { ...showCaseData };
        if (dialogState.isEditing && dialogState.currentDimensionValue) {
            const key = Object.keys(showCaseData).find(
                (k) => showCaseData[Number(k)] === dialogState.currentDimensionValue
            );
            if (key) {
                updatedData[Number(key)] = DimensionValueReference;
            }

        } else {
            const newKey = Object.keys(showCaseData).length;
            updatedData = {
                ...showCaseData,
                [newKey]: DimensionValueReference,
            };
        }
        updateShowCaseData(updatedData);
        setData({
            ...data,
            ...Object.fromEntries(Object.entries(updatedData).map(([key, value]) => [key, value])),
        });
        handleDialogClose();
    };

    const deleteRow = (index: number) => {
        setShowCaseData((prev) => {
            const updatedData = Object.keys(prev)
                .filter((key) => Number(key) !== index)
                .reduce((acc, key, newIndex) => {
                    acc[newIndex] = prev[Number(key)];
                    return acc;
                }, {} as { [key: number]: DimensionValueReference });

            if (Object.keys(updatedData).length === 0) {
                setData({} as expandedTaxonomyElement);
            } else {
                setData(updatedData as unknown as expandedTaxonomyElement);
            }

            return updatedData;
        });
    };

    const renderCells = (row: DimensionValueReference) =>
        columns.map((col) => (
            <TableCell key={col.key} align={col.header.align || 'left'}>
                {col.header.valueFormatter ? col.header.valueFormatter(row) : ""}
            </TableCell>
        ));

    const getTableType = () => {
        switch (type) {
            case 'dimensionValueReferences':
                return id === 'header' ? 'headerDimensionValues' : type;
            default:
                return type;
        }
    };

    const columns = TABLE_CONFIG()[getTableType()] || [];

    return (
        <TableContainer
            component={Paper}
            elevation={0}
            sx={{
                height: '55vh',
                maxWidth: '100%',
                overflow: 'auto',
                minHeight: '50vh',
            }}
        >
            <Table stickyHeader sx={{ minWidth: '100%' }}>
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <TableCell key={col.header.id} align={col.header.align || 'left'}>
                                {col.header.label}
                            </TableCell>
                        ))}
                        <TableCell align="right">
                            <Tooltip title={t`Add Dimension Value`}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => handleDialogOpen(null, false)}
                                >
                                    {t`Add`}
                                </Button>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(showCaseData).length > 0 ? (
                        Object.values(showCaseData).map((row, index) => (
                            <TableRow key={index} sx={{ height: '50px' }}>
                                {renderCells(row)}
                                <TableCell align="right">
                                    {isEdiTable && (
                                        <Tooltip title={t`Edit Row`}>
                                            <IconButton onClick={() => handleDialogOpen(row, true)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <IconButton onClick={() => deleteRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length + 1} align="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '50px',
                                    }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        {t`No ${type.replace(/([A-Z])/g, ' $1').toLowerCase()} available`}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
            {dialogState.open && (
                <DialogCustomDimensionValue
                    dimensionName={dimensionName}
                    open={dialogState.open}
                    onClose={handleDialogClose}
                    onSave={handleAddOrEditDimensionValue}
                    type={type}
                    dimensionValueToEdit={dialogState.currentDimensionValue || undefined}
                />
            )}
        </TableContainer>
    );
}
