import { Container, darken, Typography } from "@mui/material";
import { ElementReference, pageSelector } from "../../api/customObjectsTypes";
import Grid from "@mui/material/Grid2";
import { getTaxonomyNames } from "../../helpers/taxonomyHelpers";

interface ElementDetailedViewProps {
    element: ElementReference;
    isEven?: boolean;
    isOption?: boolean;
    page?: pageSelector;
}

const GridView = ({ label, value, size }: { label: string; value: string | string[]; size: number }) => {
    return (
        <Grid
            size={size}
            sx={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                wordWrap: "break-word",
            }}
        >
            <b style={{ marginRight: 5 }}>{label}</b>
            {Array.isArray(value) ? (
                value.map((val, index) => (
                    <span key={index} >
                        {val}
                        <br />
                    </span>
                ))
            ) : (
                <span>{value}</span>
            )}
        </Grid>
    );
};

export const ElementDetailedView = ({
    element,
    isEven,
    isOption,
    page = "normal",
}: ElementDetailedViewProps) => {

    const isLabelPage = page === "label";
    const greenBackgroundColor = isEven ? "#defff0" : "#bee6d3";
    const backgroundColor = isEven ? "#e7e7e7" : "#ffffff";

    if (!element.extension && element.taxonomyElement && isOption) {
        return null
    }

    if (!element.extension && !element.taxonomyElement) {
        return null
    }

    if (!element.extension && element.taxonomyElement && !isOption) {
        return (
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    wordWrap: "break-word",
                    padding: 0.5,
                    color: "black",
                    backgroundColor: greenBackgroundColor,
                    flex: 1,
                    "&:hover ": {
                        backgroundColor: darken(greenBackgroundColor, 0.1),
                    },
                }}
            >
                <Grid container>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            wordWrap: "break-word",
                        }}
                    >
                        {element?.taxonomyElement ? element?.name : ""}
                    </Typography>
                </Grid>
            </Container>
        );
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                display: "flex",
                overflow: "hidden",
                wordWrap: "break-word",
                padding: 2,
                backgroundColor: backgroundColor,
                borderTop: "0.1px solid black",
                borderBottom: "0.1px solid black",
                flex: 1,
                "&:hover ": {
                    backgroundColor: darken(backgroundColor, 0.1),
                },
            }}
        >
            <Grid container spacing={2} maxWidth={"false"} sx={{ flex: 1 }}>
                {page === "dimensionValue" && (
                    <>
                        <GridView label="Dimension Value" value={element?.extension?.name || ""} size={6} />
                        <GridView label="Parent" value={element?.extension?.parentReference?.name || ""} size={6} />
                        <GridView label="Company" value={element?.extension?.company?.name || ""} size={3} />
                        <GridView label="Taxonomies" value={getTaxonomyNames(element?.extension?.taxonomies || [])} size={3} />
                        <GridView label="Narrower" value={(element?.extension?.childElementReferences || []).map(
                            (el) => `${el.name}` || ""
                        )} size={isLabelPage ? 3 : 4} />
                    </>
                )}
                {page === "label" && (<>
                    <GridView label="Name" value={element?.extension?.name || ""} size={isLabelPage ? 6 : 8} />
                    <GridView label="Invert Sign" value={element?.extension?.invertSign ? "Yes" : ""} size={isLabelPage ? 2 : 2} />
                    <GridView label="Wider" value={element?.extension?.parentReference?.name || ""} size={isLabelPage ? 4 : 4} />
                    <GridView label="Company" value={element?.extension?.company?.name || ""} size={isLabelPage ? 3 : 4} />
                    <GridView label="Taxonomies" value={getTaxonomyNames(element?.extension?.taxonomies || [])} size={isLabelPage ? 3 : 4} />
                    <GridView label="Is Credit" value={element?.extension?.isCredit ? "Yes" : ""} size={2} />
                    <GridView label="Narrower" value={(element?.extension?.childElementReferences || []).map(
                        (el) => `${el.name}` || ""
                    )} size={isLabelPage ? 3 : 4} />
                </>)}
                {page === "normal" && (<>
                    <GridView label="Name" value={element?.extension?.name || ""} size={isLabelPage ? 6 : 8} />
                    <GridView label="Wider" value={element?.extension?.parentReference?.name || ""} size={isLabelPage ? 3 : 4} />
                    {isLabelPage && (
                        <GridView label="Is Credit" value={element?.extension?.isCredit ? "Yes" : ""} size={3} />
                    )}
                    <GridView label="Company" value={element?.extension?.company?.name || ""} size={isLabelPage ? 3 : 4} />
                    <GridView label="Taxonomies" value={getTaxonomyNames(element?.extension?.taxonomies || [])} size={isLabelPage ? 3 : 4} />
                    <GridView label="Narrower" value={(element?.extension?.childElementReferences || []).map(
                        (el) => `${el.name}` || ""
                    )} size={isLabelPage ? 3 : 4} />
                </>)}
            </Grid>
        </Container>
    );
};
