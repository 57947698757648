import { t } from "@lingui/core/macro";
import { DataTableColumn } from "../dataTable";
import { getTaxonomyNames } from "../../helpers/taxonomyHelpers";
import { ElementReference, DimensionValue, dataMatchingToNavBar } from "../../api/customObjectsTypes";
import { Button } from "@mui/material";
import { ColumnProps } from "../../api/types";
import { deleteGlobalRequest } from "../../api/customObjects";

/*  Custom Object preDifined Data
------------------------------------------------------------------------ */
/*  Row Widths - These are used to set the width of the columns in the tables of the main custom object tables 
------------------------------------------------------------------------ */

const labelRowWidth = 200;
const elementRowWidth = 200;
const companyRowWidth = 100;
const tagGroupRowWidth = 150;
const dateSpectrumRowWidth = 130;
const languageRowWidth = 50;
const taxonomiesRowWidth = 100;
const dimensionValuesRowWidth = 100;
const additionalElementsRowWidth = 100;
const isLabelRowWidth = 100;
const smallNormalRowWidth = 25;
const widerRowWidth = 250;

// - Api routes 

const AvaliableContexts = "api/custom-objects/contexts/available";
const AvaliableSettingCodes = "api/configuration/setting-codes";
const parserDataTypes = "api/configuration/data-types";
const parserPeriods = "api/configuration/periods";

/*  CustomObject - dialog Tables 
------------------------------------------------------------------------ */
export const TABLE_CONFIG = (): Record<string, ColumnProps[]> => ({
    basicList: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                align: 'left'
            },
            key: "dimensionValue",
            width: 200,
        }
    ],
    childElementReferences: [
        {
            header: {
                id: "element",
                label: t`Child Elements`,
                align: 'left',
                valueFormatter: (row: ElementReference) => {
                    return row?.name || "";
                }
            },
            key: "element",
            width: 200,
        }
    ],
    dimensionLabels: [
        {
            header: {
                id: "lang",
                label: t`Language`,
                align: 'left',
                valueFormatter: (row) => row?.lang
            },
            key: "lang",
            width: 200,
        },
        {
            header: {
                id: "label",
                label: t`Label`,
                align: 'left',
                valueFormatter: (row) => row?.text
            },
            key: "label",
            width: 200,
        }
    ],
    dimensionValueReferences: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.name || row?.customDimensionValue?.dimension?.extensionDimension?.name || "";
                },
            },
            key: "dimensionValue",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.taxonomyValue?.dimensionReference
                        ? `${row?.taxonomyValue?.dimensionReference.namespaceAbbreviation}:${row?.taxonomyValue?.dimensionReference.name}`
                        : row?.customDimensionValue?.dimension?.name || "";
                }
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`,
                valueFormatter: (row: DimensionValue) => {
                    if (row?.dimensionValue?.onlyWhenConsolidated !== undefined && row?.dimensionValue?.onlyWhenConsolidated !== null) {
                        return row.dimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    if (row?.customDimensionValue?.onlyWhenConsolidated !== undefined && row?.customDimensionValue?.onlyWhenConsolidated !== null) {
                        return row.customDimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    return "";
                },
            },
            key: "consolidated",
            width: 200,
        }
    ],
    labelDimensionValues: [
        {
            header: {
                id: "language",
                label: t`Language`,
                valueFormatter: (row) => row?.language,
            },
            key: "language",
            width: 200,
        },
        {
            header: {
                id: "label",
                label: t`Label`,
                valueFormatter: (row) => row?.label,
            },
            key: "label",
            width: 200,
        }
    ],
    contextDimensionValues: [
        {
            header: {
                id: "dimensionName",
                label: t`Dimension Name`,
                valueFormatter: (row) => row?.name,
            },
            key: "dimensionName",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row: { dimensionValue: DimensionValue }) => {
                    return row?.dimensionValue?.dimensionName || row?.dimensionValue?.customDimensionValue?.dimension?.name || "";
                }
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`,
                valueFormatter: (row: DimensionValue) => {
                    if (row?.dimensionValue?.onlyWhenConsolidated !== undefined && row?.dimensionValue?.onlyWhenConsolidated !== null) {
                        return row.dimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    if (row?.customDimensionValue?.onlyWhenConsolidated !== undefined && row?.customDimensionValue?.onlyWhenConsolidated !== null) {
                        return row.customDimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    return "";
                },
            },
            key: "consolidated",
            width: 200,
        }
    ],
    tagsDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`
            },
            key: "dimensionValue",
            width: 200,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row: { dimensionValue: DimensionValue }) => {
                    return row?.dimensionValue?.dimensionName || row?.dimensionValue?.customDimensionValue?.dimension?.name || "";
                }
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`,
                valueFormatter: (row: DimensionValue) => {
                    if (row?.dimensionValue?.onlyWhenConsolidated !== undefined && row?.dimensionValue?.onlyWhenConsolidated !== null) {
                        return row.dimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    if (row?.customDimensionValue?.onlyWhenConsolidated !== undefined && row?.customDimensionValue?.onlyWhenConsolidated !== null) {
                        return row.customDimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    return "";
                },
            },
            key: "consolidated",
            width: 200,
        }
    ],
    headerDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                valueFormatter: (row: { dimensionValue: DimensionValue }) => {
                    return row?.dimensionValue?.name || row.dimensionValue?.dimensionValue?.name || "";
                },
            },
            key: "dimensionValue",
            width: 350,
        },
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                valueFormatter: (row: { dimensionValue: DimensionValue }) => {
                    return row?.dimensionValue?.dimensionName || row?.dimensionValue?.customDimensionValue?.dimension?.name || "";
                }
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "typedMemberValue",
                label: t`Typed Member Value`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.typedMemberValue || "";
                }
            },
            key: "typedMemberValue",
            width: 200,
        },
        {
            header: {
                id: "consolidated",
                label: t`Consolidated`,
                valueFormatter: (row: DimensionValue) => {
                    if (row?.dimensionValue?.onlyWhenConsolidated !== undefined && row?.dimensionValue?.onlyWhenConsolidated !== null) {
                        return row.dimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    if (row?.customDimensionValue?.onlyWhenConsolidated !== undefined && row?.customDimensionValue?.onlyWhenConsolidated !== null) {
                        return row.customDimensionValue.onlyWhenConsolidated ? "True" : "False";
                    }
                    return "";
                },
            },
            key: "consolidated",
            width: 200,
        }
    ],
    childDimensionValues: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.name || row.customDimensionValue?.dimension?.extensionDimension?.name || "";
                },
            },
            key: "dimensionValue",
            width: 200,
        }
    ],
    additionalElementReferences: [
        {
            header: {
                id: "dimensionValue",
                label: t`Dimension Value`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.name || row.customDimensionValue?.dimension?.extensionDimension?.name || "";
                },
            },
            key: "dimensionValue",
            width: 200,
        },
    ],
    extension: [
        {
            header: {
                id: "structure",
                label: t`Structure`,
                valueFormatter: (row: DimensionValue) => row?.name || "",
            },
            key: "structure",
            width: 200,
        }
    ],
    extensionStructureReferences: [
        {
            header: {
                id: "structure",
                label: t`Structure`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.name || row?.customDimensionValue?.dimension?.extensionDimension?.name || "";
                },
            },
            key: "structure",
            width: 200,
        }
    ],
    hierarchyElementReferences: [
        {
            header: {
                id: "hierarchy",
                label: t`Hierarchy`,
                valueFormatter: (row: DimensionValue) => {
                    return row?.name || row?.customDimensionValue?.dimension?.extensionDimension?.name || "";
                },
            },
            key: "hierarchy",
            width: 200,
        }
    ],
    onlyDimension: [
        {
            header: {
                id: "dimension",
                label: t`Dimension`
            },
            key: "dimension",
            width: 200,
        }
    ],
});

/*  Custom Object Templates 
------------------------------------------------------------------------ */
/* Templates are used to create the structure of the custom object dialog depending on the type of object being created or edited.   
------------------------------------------------------------------------ */

export const tagsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "tags",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: false },
            tagGroup: { key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null },
            isLabel: { key: "isLabel", name: t`Is Label`, fieldType: "checkbox", value: false },
            isSettingTag: { key: "isSettingTag", name: t`Is Setting`, fieldType: "checkbox", value: false },
            mergePresentation: { key: "mergePresentation", name: t`Merge Presentation`, fieldType: "checkbox", value: false },
            isFootnote: { key: "isFootnote", name: t`Is Footnote`, fieldType: "checkbox", value: false },
            isArea: { key: "isArea", name: t`Is Area`, fieldType: "checkbox", value: false },
            lang: { key: "lang", name: t`Language`, fieldType: "languages", value: null },
            periodId: { key: "periodId", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        hierarchyElementReferences: {
            navBarName: t`Extension`,
        },
        extensionStructureReferences: {
            navBarName: t`Extension Structure`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
        globals: {
            customTaxonomyGroup: { key: "customTaxonomyGroup", name: t`Custom Taxonomy Group`, fieldType: "text", value: null },
        },
    };
};

export const headerTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "header",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            elementReference: { key: "element", name: t`Element`, fieldType: "element", value: null },
            tagGroup: { key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            skipTagging: { key: "skipTagging", name: t`Skip Tagging`, fieldType: "checkbox", value: null },
            isLabelFootnote: { key: "isLabelFootnote", name: t`Is Label Footnote`, fieldType: "checkbox", value: null },
            lang: { key: "lang", name: t`Language`, fieldType: "searchSelect", value: null },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: null },
        },
        overrides: {
            navBarName: t`Overrides`,
            periodId: { key: "periodId", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts },
            currencyCode: { key: "currencyCode", name: t`Currency`, fieldType: "text", value: null },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "languages", value: null },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null, placeholder: "" },
        },
        searchHint: {
            navBarName: t`Search hint`,
            searchDirection: { key: "searchDirection", name: t`Search direction`, fieldType: "select", value: null, options: ["None", "Before", "After", "In"] },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
        notes: {
            navBarName: t`Note`,
        },
        globals: {
            overrideTypedMemberValueReference: { key: "overrideTypedMemberValueReference", name: t`Override Typed Memeber`, fieldType: "dimension", value: null },
        },
        hiddenValues: {
            type: { key: "type", value: null },
            table: { key: "tableItem", value: null },
            row: { key: "row", value: null },
        }
    };
};

export const labelsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "labels",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            elementReference: { key: "elementReference", name: t`Element`, fieldType: "element", value: null },
            nameElement: { key: "nameElement", name: t`Name Element`, fieldType: "element", value: null },
            tagGroup: { key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            lang: { key: "lang", name: t`Language`, fieldType: "languages", value: null },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: null },
        },
        overrides: {
            navBarName: t`Overrides`,
            periodId: { key: "periodId", name: t`Context`, fieldType: "endpoint", value: null, endpointUrl: AvaliableContexts },
            currencyCode: { key: "currencyCode", name: t`Currency`, fieldType: "text", value: null },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "languages", value: null },
            transformation: { key: "transformation", name: t`Transformation`, fieldType: "text", value: null },
            before: { key: "before", name: t`Before`, fieldType: "text", value: null },
            merge: { key: "merge", name: t`Merge`, fieldType: "alternateCheckbox", value: null, alternative: true },
            allowHtml: { key: "allowHtml", name: t`Allow HTML`, fieldType: "alternateCheckbox", value: null, alternative: true },
            skipSpace: { key: "skipSpace", name: t`Skip Space`, fieldType: "alternateCheckbox", value: null, alternative: true },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null },
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Value`,
        },
        additionalElementReferences: {
            navBarName: t`Additional Elements`,
        },
        taxonomies: [],
        usage: {
            navBarName: t`Usage`,
        },
        notes: {
            navBarName: t`Note`,
        },
        globals: {
            overrideTypedMemberValueReference: { key: "overrideTypedMemberValueReference", name: t`Override Typed Memeber`, fieldType: "dimension", value: null },
        },
        hiddenValues: {
            type: { key: "type", value: null },
            table: { key: "tableItem", value: null },
            row: { key: "row", value: null },
        }
    };
};

export const customDimensionsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "dimensions",
        details: {
            navBarName: t`Dimension Value`,
            name: { key: "name", name: t`Custom Dimension Name`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
        },
        dimensionLabels: {
            navBarName: t`Dimension Labels`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        customTags: {
            navBarName: t`Custom Tags`,
        },
        contexts: {},
        taxonomies: [],
    };
};

export const dimensionValueTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "dimensionValue",
        details: {
            navBarName: t`Dimension Value`,
            dimension: { key: "dimension", name: t`Dimension`, fieldType: "dimension", value: null, isAlternate: "alternateDimension" },
            customMemberName: { key: "customMemberName", name: t`Custom Value`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            isTypedMember: { key: "isTypedMember", name: t`Is Typed Member`, fieldType: "checkbox", value: null },
            isDefaultMember: { key: "isDefaultMember", name: t`Is Default Member`, fieldType: "checkbox", value: null },
            parentMember: { key: "parentMember", name: t`Parent Member`, fieldType: "dimension", value: null, isAlternate: "alternateParentMember" },
        },
        childDimensionValues: {
            navBarName: t`Child Dimension Values`,
        },
        taxonomies: [],
        dimensionLabels: {
            navBarName: t`Value Labels`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
        customTags: {
            navBarName: t`Custom Tags`,
        },
        contexts: {
            navBarName: t`Contexts`,
        },
        customDimensionValues: {
            navBarName: t`Custom Dimension Values`,
        },
    };
};

export const extensionElementsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "extensionElements",
        details: {
            navBarName: t`Details`,
            name: { key: "name", name: t`Element Name`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "company", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            parserDataTypeId: { key: "parserDataTypeId", name: t`Element Data Type`, fieldType: "parserDataType", value: null, endpointUrl: parserDataTypes },
            isInstant: { key: "isInstant", name: t`Is Instant`, fieldType: "checkbox", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "alternateCheckbox", value: null },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "checkbox", value: null },
            parentReference: { key: "parentReference", name: t`Parent`, fieldType: "element", value: null }
        },
        childElementReferences: {
            navBarName: t`Child Elements`,
        },
        dimensionLabels: {
            navBarName: t`Labels`,
        },
        taxonomies: [],
        documentation: {
            navBarName: t`Documentation`,
        },
        usage: {
            navBarName: t`Usage`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
        },
    };
};

export const settingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "settings",
        details: {
            navBarName: t`Details`,
            settingName: { key: "SettingName", name: t`Name`, fieldType: "endpoint", endpointUrl: AvaliableSettingCodes, urlType: "GET", value: null },
            required: { key: "required", name: t`Required`, fieldType: "checkbox", value: null }
        },
        dimensionLabels: {
            navBarName: t`Labels`,
        },
        taxonomies: [],
    };
};

export const ContextSettingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "contextSettings",
        details: {
            navBarName: t`Details`,
            context: { key: "context", name: t`Context`, fieldType: "text", value: null, endpointUrl: AvaliableContexts },
            period: { key: "period", name: t`Period`, fieldType: "endpoint", value: null, endpointUrl: parserPeriods, urlType: "GET" }
        },
        dimensionValueReferences: {
            navBarName: t`Dimension Values`,
        },
        taxonomies: [],
    };
};

export const taxonomyElementTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "taxonomyElement",
        details: {
            navBarName: t`Details`,
            label: { key: "name", name: t`Name`, fieldType: "text", value: null, disabled: true },
            nameSpace: { key: "nameSpace", name: t`Name Space`, fieldType: "text", value: null, disabled: true },
            periodType: { key: "periodType", name: t`Period Type`, fieldType: "select", value: null, disabled: true },
            taxonomyDataType: { key: "taxonomyDataType", name: t`Taxonomy Data Type`, fieldType: "text", value: null, disabled: true },
            parserDataType: { key: "parserDataType", name: t`Parser Data Type`, fieldType: "select", value: null },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "text", value: null, disabled: true },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "checkbox", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            rawNumber: { key: "rawNumber", name: t`Raw Number`, fieldType: "checkbox", value: null },
        },
        validators: {
            navBarName: t`Validators`,
        },
        labels: {
            navBarName: t`Labels`,
        },
        customLabels: {
            navBarName: t`Custom Labels`,
        },
        usage: {
            navBarName: t`Usage`,
        },
    };
};

/*  Custom Object Table Columns 
------------------------------------------------------------------------ */

export const ContextColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
            },
            key: "period",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 200,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            key: "dimensionValues",
            width: 200,
        },
    ];
}

export const Tagscolumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                width: labelRowWidth
            },
            key: "label",
            shouldWrapVertically: true,
            width: labelRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                shouldBeFiltered: true,
                shouldWrapVertically: true,
            },
            key: "tagGroup",
            shouldWrapVertically: true,
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
            },
            valueFormatter: (row) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
                width: dateSpectrumRowWidth,
            },
            key: "dateSpectrum",
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Is Label`,
                width: isLabelRowWidth,
            },
            key: "isLabel",
            width: isLabelRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Select Taxonomy`,
                inputType: "select",
                width: taxonomiesRowWidth,
            },
            valueFormatter: (row) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
            shouldWrapVertically: true,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
                shouldBeFiltered: false,
                width: dimensionValuesRowWidth,
            },
            valueFormatter: (row) => {
                return renderDimensionValues(row);
            },
            key: "dimensionValues",
            width: dimensionValuesRowWidth,
            shouldWrapVertically: true,
        },
    ];
};

export const TagsAltColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Label`,
                inputType: "text",
                width: labelRowWidth
            },
            key: "label",
            shouldWrapVertically: true,
            width: labelRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
            },
            valueFormatter: (row) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                shouldWrapVertically: true,
            },
            key: "tagGroup",
            shouldWrapVertically: true,
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
                width: dateSpectrumRowWidth,
            },
            key: "dateSpectrum",
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                inputType: "select",
                optionsHeaderText: t`Select Is Label`,
                width: isLabelRowWidth,
            },
            key: "isLabel",
            width: isLabelRowWidth,
        },
    ];
};

export const HeadersColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "searchLabel",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                width: labelRowWidth,
                optionsHeaderText: t`Label`,
            },
            shouldWrapVertically: true,
            key: "searchLabel",
            width: labelRowWidth,
            valueFormatter: (row) => {
                return renderisSafeLabel(row);
            },
        },
        {
            header: {
                id: "elementReference_name",
                label: t`Element`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element`,
                width: elementRowWidth,
            },
            valueFormatter: (row: any) => {
                return renderElement(row);
            },
            key: "elementReference_name",
            shouldWrapVertically: true,
            width: elementRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
                width: companyRowWidth,
            },
            valueFormatter: (row) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                optionsHeaderText: t`Select Tag Group`,
                inputType: "select",
                width: tagGroupRowWidth,
                shouldBeFiltered: true,
                shouldWrapVertically: true,
            },
            key: "tagGroup",
            shouldWrapVertically: true,
            width: tagGroupRowWidth,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                optionsHeaderText: t`Select Date Spectrum`,
                width: dateSpectrumRowWidth,
                inputType: "select",
                shouldBeFiltered: true,
                shouldWrapVertically: true,
            },
            key: "dateSpectrum",
            shouldWrapVertically: true,
            width: dateSpectrumRowWidth,
        },
        {
            header: {
                id: "lang",
                label: t`Language`,
                optionsHeaderText: t`Select Language`,
                shouldBeFiltered: true,
                inputType: "select",
                width: languageRowWidth,
            },
            key: "lang",
            shouldWrapVertically: true,
            width: languageRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                width: taxonomiesRowWidth,
            },
            valueFormatter: (row) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            shouldWrapVertically: true,
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "dimensionValueReferences_dimensionValue_name",
                width: dimensionValuesRowWidth,
                label: t`Dimension Values`,
                shouldBeFiltered: true,
                inputType: "text",
            },
            valueFormatter: (row) => {
                return renderDimensionValues(row);
            },
            key: "dimensionValueReferences_dimensionValue_name",
            shouldWrapVertically: true,
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                width: additionalElementsRowWidth,
            },
            valueFormatter: (row) => {
                return renderAdditionalElements(row);
            },
            key: "additionalElements",
            shouldWrapVertically: true,
            width: additionalElementsRowWidth,
        }
    ];
}

export const labelsColumns: <T>() => DataTableColumn<T>[] = () => {
    return HeadersColumns();
}

export const customDimensionColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "name",
                label: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Dimension`,
            },
            valueFormatter: (row: any) => {
                return row.name || "";
            },
            key: "name",
            width: 400,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row: any) => {
                return renderCompany(row);
            },
            key: "company_name",
            width: companyRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
    ]
}

export const dimensionValuesColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "dimension_name",
                label: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`dimension`,
            },
            valueFormatter: (row) => {
                return renderDimension(row);
            },
            shouldWrapVertically: true,
            key: "dimension_name",
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "valueName",
                label: t`Value`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Value`,
            },
            valueFormatter: (row: any) => {
                return row.customMemberName || row.MemberName;
            },
            shouldWrapVertically: true,
            key: "valueName",
            width: 200,
        },
        {
            header: {
                id: "isTypedMember",
                label: t`Is Typed`,
            },
            valueFormatter: (row: any) => {
                return row.isTypedMember ? "Yes" : "";
            },
            key: "isTypedMember",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "isDefaultMember",
                label: t`Is Default`,
            },
            valueFormatter: (row: any) => {
                return row.isDefaultMember ? "Yes" : "";
            },
            key: "isDefaultMember",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row) => {
                return renderCompany(row);
            },
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row: any) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "parentMember_name",
                label: t`Wider`,
            },
            valueFormatter: (row: any) => {
                return renderWider(row);
            },
            shouldWrapVertically: true,
            key: "wider",
            width: widerRowWidth,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            valueFormatter: (row: any) => {
                return (
                    <div>
                        {row?.childDimensionValues?.map((t: any, index: number) => (
                            <div key={index}>{t.name || ""}</div>
                        ))}
                    </div>
                );
            },
            shouldWrapVertically: true,
            key: "narrower",
            width: widerRowWidth,
        },

    ]
}

export const extensionElementsColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "name",
                label: t`Element Name`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Element Name`,
                valueFormatter: (row: any) => {
                    return row.name || "";
                }
            },
            shouldWrapVertically: true,
            key: "name",
            width: 300,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row) => {
                return renderCompany(row);
            },
            shouldWrapVertically: true,
            key: "company",
            width: companyRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row) => {
                return renderTaxonomies(row);
            },
            shouldWrapVertically: true,
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "parentReference_name",
                label: t`Wider`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Wider`,
            },
            valueFormatter: (row: any) => {
                return row?.parentReference?.name || "";
            },
            shouldWrapVertically: true,
            key: "parentReference_name",
            width: 280,
        },
        {
            header: {
                id: "childElementReferences",
                label: t`Narrower`,
                shouldBeFiltered: false,
                inputType: "text",
                optionsHeaderText: t`Narrower`,
            },
            valueFormatter: (row: any) => {
                return (
                    <div>
                        {row.childElementReferences?.map((child: any, index: number) => (
                            <div key={index}>{child.name}</div>
                        ))}
                    </div>
                );
            },
            shouldWrapVertically: true,
            key: "childElementReferences",
            width: 280,
        },
    ]
}

export const settingsColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "settingName",
                label: t`Setting`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Setting`,
            },
            key: "settingName",
            width: 150,
        },
        {
            header: {
                id: "required",
                label: t`Required`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Required`,
                valueForamtter: (row: any) => {
                    return row.required ? "Yes" : "";
                },
            },
            key: "required",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "Labels",
                label: t`Labels`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Labels`,
            },
            valueFormatter: (row) => {
                return renderLabels(row);
            },
            shouldWrapVertically: true,
            key: "Labels",
            width: labelRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth
        },
    ]
}

export const contextSettingsColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Period`,
            },
            key: "period",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            valueFormatter: (row) => {
                return renderTaxonomies(row);
            },
            key: "taxonomies",
            width: taxonomiesRowWidth,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            valueFormatter: (row) => {
                return renderDimensionValues(row);
            },
            key: "dimensionValues",
            width: dimensionValuesRowWidth,
        },
    ]
}

export const globalRequestColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "entityReference_name",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Label`,
            },
            valueFormatter: (row: any) => {
                return row?.entityReference?.name || "";
            },
            key: "entityReference_name",
            width: labelRowWidth,
        },
        {
            header: {
                id: "entity",
                label: t`Entity`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Entity`
            },
            key: "entity",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "company_name",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company`,
            },
            valueFormatter: (row) => {
                return renderCompany(row);
            },
            key: "company_name",
            width: companyRowWidth,
        },
        {
            header: {
                id: "requester_name",
                label: t`Requester`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Requester`
            },
            valueFormatter: (row: any) => {
                return row?.requester?.name || "";
            },
            key: "requester_name",
            width: isLabelRowWidth,
        },
        {
            header: {
                id: "delete",
                label: ``,
                shouldBeFiltered: false,
            },
            valueFormatter: (row: any) => {
                return <Button
                    variant="text"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteGlobalRequest(row?.entity, row?.entityReference?.id);
                    }}
                >
                    {t`Delete`}
                </Button>;
            },
            key: "deleteGlobalReq",
            width: isLabelRowWidth,
        },
    ]
}

export const historyLogColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "reference_name",
                label: t`Label`,
                shouldBeFiltered: false,
                inputType: "text",
                optionsHeaderText: t`Label`,
            },
            key: "reference_name",
            width: labelRowWidth,
        },
        {
            header: {
                id: "user_name",
                label: t`User`,
                shouldBeFiltered: true,
                inputType: "text",
            },
            key: "user_name",
            width: 200,
        },
        {
            header: {
                id: "type",
                label: t`Type`,
                shouldBeFiltered: true,
                inputType: "select",
            },
            key: "type",
            width: 150,
        },
        {
            header: {
                id: "action",
                label: t`Action`,
                shouldBeFiltered: true,
                inputType: "select",
            },
            key: "action",
            width: 150,
        },
        {
            header: {
                id: "timeStamp",
                label: t`Date`,
                shouldBeFiltered: false,
                inputType: "text",
            },
            key: "timeStamp",
            width: 200,
        }
    ]
}

export const reviewColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "reference_name",
                label: t`Reference Name`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Reference Name`,
            },
            key: "reference_name",
            width: 200,
        },
        {
            header: {
                id: "company_name",
                label: t`Company Name`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Company Name`,
            },
            key: "company_name",
            width: 200,
        },
        {
            header: {
                id: "user_name",
                label: t`User Name`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`User Name`,
            },
            key: "user_name",
            width: 200,
        },
        {
            header: {
                id: "type",
                label: t`Type`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Type`,
            },
            key: "type",
            width: 150,
        },
        {
            header: {
                id: "action",
                label: t`Action`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Action`,
            },
            key: "action",
            width: 150,
        },
        {
            header: {
                id: "timeStamp",
                label: t`Timestamp`,
                shouldBeFiltered: false,
                inputType: "text",
                optionsHeaderText: t`Timestamp`,
            },
            key: "timeStamp",
            width: 200,
        },
        {
            header: {
                id: "reviewed",
                label: t`Reviewed`,
                shouldBeFiltered: false,
                inputType: "text",
                optionsHeaderText: t`Reviewed`,
            },
            key: "reviewed",
            width: 150,
        },
    ];
};


export const taxonomyDiemsnionValueColumns: <T>() => DataTableColumn<T>[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
                optionsHeaderText: t`Dimension`,
                shouldBeFiltered: true,
                inputType: "text",
                width: 600,
            },
            key: "dimension",
            width: dimensionValuesRowWidth,
        },
        {
            header: {
                id: "value",
                label: t`Value`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Value`,
                inputType: "select",
                width: 150,
            },
            key: "value",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "isTypedMember",
                label: t`Is Typed Member`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Is Typed Member`,
                inputType: "select",
                width: 250,
            },
            key: "isTypedMember",
            width: smallNormalRowWidth,
        },
        {
            header: {
                id: "isDefault",
                label: t`Is Default`,
                shouldBeFiltered: true,
                inputType: "select",
                width: 250,
            },
            key: "isDefault",
            width: smallNormalRowWidth,
        },
    ];
};

/* Renderers for columns with complex data 
------------------------------------------------------------------------ */

const renderTaxonomies = (row: any): JSX.Element => {
    if (row && Array.isArray(row.taxonomies)) {
        const taxonomyNames = getTaxonomyNames(row.taxonomies);
        return (
            <div>
                {taxonomyNames
                    .sort((a, b) => a.localeCompare(b))
                    .map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
            </div>
        );
    }
    return <></>;
};

const renderCompany = (row: any): string => {
    if (row && row.company && typeof row.company === "object") {
        return row.company.name || "";
    }
    return "";
};

const renderElement = (row: any): string => {
    if (row) {
        if (row?.elementReference && row?.elementReference?.extension) {
            return `*${row?.elementReference?.name || ""}` || "";
        } else {
            return row?.elementReference?.name || "";
        }
    }
    return "";
};

const renderDimensionValues = (row: any): JSX.Element => {
    if (row && Array.isArray(row.dimensionValueReferences)) {
        return (
            <div>
                {row?.dimensionValueReferences?.map((dimensionValue: any, index: number) => (
                    <div key={index}>{dimensionValue?.dimensionValue?.name || dimensionValue?.name || ""} <br /></div>
                ))}
            </div>
        );
    }
    return <></>;
};

const renderisSafeLabel = (row: any): string => {
    if (row) {
        return row?.isSafe ? `*${row?.label}` : `${row?.label} `
    };
    return "";
}

const renderDimension = (row: any): string => {
    if (row && row?.dimension && typeof row?.dimension === "object") {
        return row?.dimension?.name || "";
    }
    return "";
};

const renderAdditionalElements = (row: any): string => {
    if (row && Array.isArray(row.additionalElementReferences)) {
        return row?.additionalElementReferences?.map((t: any) => {
            return t?.taxonomyElement?.Name || t?.name || "";
        }).join(", ");
    }
    return "";
};

const renderWider = (row: any): string => {
    return row?.parentMember?.name || row?.dimension?.name || row?.taxonomyValue?.dimensionReference?.name || "";
}

const renderLabels = (row: any): JSX.Element => {
    if (row && Array.isArray(row.labels)) {
        return (
            <div>
                {row?.labels?.map((label: any, index: number) => (
                    <div key={index}>{label.text} <br /></div>
                ))}
            </div>
        );
    }
    return <></>;
};
