import { Tagscolumns, HeadersColumns, labelsColumns, customDimensionColumns, dimensionValuesColumns, extensionElementsColumns, settingsColumns, contextSettingsColumns, globalRequestColumns, taxonomyDiemsnionValueColumns, TagsAltColumns, historyLogColumns, reviewColumns } from "./customObjectsPreData";

const columnsMapping = {
    tags: Tagscolumns,
    header: HeadersColumns,
    labels: labelsColumns,
    tableItems: labelsColumns,
    customDimensions: customDimensionColumns,
    dimensionValue: dimensionValuesColumns,
    extensionElements: extensionElementsColumns,
    settings: settingsColumns,
    contextSettings: contextSettingsColumns,
    globalRequests: globalRequestColumns,
    taxonomyDimensionValues: taxonomyDiemsnionValueColumns,
    historyLog: historyLogColumns,
    review: reviewColumns,
    consistencyCheck: [],
};

export const getColumns = (selectTemplate: string) => {
    return columnsMapping[selectTemplate as keyof typeof columnsMapping];
};

export const getAlternativeColumnsMapping = (selectTemplate: string) => {
    switch (selectTemplate) {
        case "customLabels":
            return labelsColumns<{ id: string }>();
        case "customHeaders":
            return HeadersColumns<{ id: string }>();
        case "customTags":
            return TagsAltColumns<{ id: string }>();
        case "contexts":
            return contextSettingsColumns<{ id: string }>();
        case "customDimensionValues":
            return dimensionValuesColumns<{ id: string }>();
        default:
            return [];
    }
}