import { ListItemText, Autocomplete, TextField, debounce } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState, useCallback } from "react";
import AlertSnackbar from "../alertSnackbar";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import { usePostQuery } from "../../hooks/usePostQuery";
import { DimensionDetailedView } from "./dimensionDetailedView";
import { DimensionValueReference, DimensionValue, pageSelector } from "../../api/customObjectsTypes";

export const DimensionLabel = ({
    label,
    selectedValue,
    setSelectedValue,
    value,
    onValueChange,
    preDefinedOptions,
    placeholder,
    disabled,
    includeCustom = true,
    showDetailed = true,
    page = "normal",
    alternateUrl,
    dimensionName,
}: {
    includeCustom?: boolean | null;
    label: string | undefined;
    preDefinedOptions?: any[];
    selectedValue: any | null;
    setSelectedValue: (value: any | null) => void;
    value?: DimensionValue;
    onValueChange?: (event: any) => void;
    placeholder?: string;
    disabled?: boolean;
    showDetailed?: boolean;
    page?: pageSelector;
    alternateUrl?: "alternateDimension" | "alternateParentMember" | "alternateElement";
    dimensionName?: string;
}) => {
    const [query, setQuery] = useState("");
    const [optionsState, setOptionsState] = useState<any[]>(preDefinedOptions ?? []);
    const [cacheBuster] = useState(crypto.randomUUID());
    const [abortSignal, setAbortSignal] = useState(true);
    const [req, setReq] = useState({
        searchString: query,
        pageCount: 20,
        taxonomyIds: getSelectedTaxonomyIds(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleQueryChange = useCallback(debounce((newQuery: string) => {
        setReq({
            searchString: newQuery,
            pageCount: 20,
            taxonomyIds: getSelectedTaxonomyIds(),
        });
        setAbortSignal(false);
    }, 500), [setReq, setAbortSignal]);

    useEffect(() => {
        if (query.length > 0) {
            handleQueryChange(query);
        }
    }, [query, handleQueryChange]);

    const { data, loading, error } = usePostQuery<any[]>(
        alternateUrl === "alternateParentMember" || alternateUrl === "alternateElement"
            ? `/api/custom-objects/dimension-value-references/dimensions/${dimensionName}/members/search-by-name?cb=${cacheBuster}${includeCustom !== null ? `&includeCustom=${includeCustom}` : ''}`
            : `/api/custom-objects/${alternateUrl === "alternateDimension" ? 'dimension-references' : 'dimension-value-references'}/search?cb=${cacheBuster}${includeCustom !== null ? `&includeCustom=${includeCustom}` : ''}`,
        req,
        abortSignal
    );

    useEffect(() => {
        if (data) {
            setOptionsState(data as DimensionValueReference[]);
        }
    }, [data]);

    useEffect(() => {
        if (value === null) {
            setSelectedValue(null);
        }
    }, [value]);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }} maxWidth={"false"}>
            {error &&
                <AlertSnackbar
                    alertMessage={{ message: error.message, severity: "error" }}
                    updateAlertStatus={() => { }}
                />
            }
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <Autocomplete
                    disabled={disabled}
                    sx={{
                        "& .MuiAutocomplete-inputRoot.Mui-disabled": {
                            cursor: "not-allowed",
                        },
                    }}
                    forcePopupIcon={false}
                    options={optionsState}
                    getOptionLabel={(option) => option?.name || ""}
                    filterOptions={(options) => {
                        const customOptions = options.filter((option) => option.isCustom === false);
                        const otherOptions = options.filter((option) => option.isCustom !== false);
                        return [...customOptions, ...otherOptions];
                    }}
                    renderOption={(props, option, state) => {
                        const { key, ...restProps } = props;
                        const isEven = state.index % 2 === 0;
                        return (
                            <li key={state.index} {...restProps}
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    listStyleType: "none",
                                }}
                            >
                                {option &&
                                    <DimensionDetailedView
                                        element={option}
                                        isEven={isEven}
                                        page={page}
                                    />}
                            </li>
                        );
                    }}
                    loading={loading}
                    value={selectedValue || null}
                    onChange={(_, newValue) => {
                        setSelectedValue(newValue || null);
                        if (onValueChange && newValue) {
                            onValueChange(newValue);
                        }
                    }}
                    onInputChange={(_, newInputValue, reason) => {
                        if (reason === "clear") {
                            setSelectedValue(null);
                            if (onValueChange) {
                                onValueChange(null);
                            }
                        }
                    }}
                   /*  onBlur={(event) => {
                        const inputValue = (event.target as HTMLInputElement).value;
                        const matchingOptions = optionsState.filter(option =>
                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        if (matchingOptions.length === 1) {
                            setSelectedValue(matchingOptions[0]);
                            onValueChange?.(matchingOptions[0].value);
                        }
                    }} */
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            sx={{ width: "100%" }}
                            variant="standard"
                            disabled={disabled}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                    )}
                />
                {selectedValue && showDetailed &&
                    <DimensionDetailedView
                        isOption={selectedValue}
                        element={selectedValue}
                        page={page}
                        isEven={optionsState.indexOf(selectedValue) % 2 === 0}
                    />}
            </Grid>
        </Grid>
    );
};

export default DimensionLabel;