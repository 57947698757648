import { Box, Button, Container, Typography } from "@mui/material";
import { ObjectsWrapper } from "./objectsWrapper";
import { t } from "@lingui/core/macro";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { getSelectedTaxonomyIds } from "../../helpers/taxonomyHelpers";
import { usePostQuery } from "../../hooks/usePostQuery";
import { Loader } from "../loader";
import { cConsistencyCheckColumns, cConsistencyError, consistencyLabel } from "../../api/customObjectsTypes";
import CustomObjectsDialog from "./customObjectsDialog";

export const ConsistencyCheck = () => {

    const [callApi, setCallApi] = useState(false);
    const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());

    const taxonomies = getSelectedTaxonomyIds();

    const [req, setReq] = useState({});

    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

    const [selectedRow, setSelectedRow] = useState<consistencyLabel | undefined>(undefined);

    useEffect(() => {
        setReq(taxonomies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data, loading } = usePostQuery<cConsistencyCheckColumns>(
        `api/custom-objects/consistency-check?taxonomiesToCheck=${taxonomies}&cb=${cacheBuster}`,
        req,
        !callApi
    );
    const handleCheck = () => {
        setCallApi(!callApi);
        setCacheBuster(crypto.randomUUID());
    };

    const handleCloseDialog = () => {
        setSelectedId(undefined);
    }

    const getUrl = (entity: string | undefined) => {
        const usableEntity = entity?.toLowerCase()
        switch (usableEntity) {
            case "customlabel":
                return "labels";
            case "customtags":
                return "tags";
            case "customheader":
                return "headers";
            case "customDimension":
                return "dimension";
            case "customdimensionvalue":
                return "dimensionValue";
            case "customExtensionElement":
                return "extensionElement";
            case "customSettings":
                return "settings";
            default:
                return "header";
        }
    };

    const getTemplate = (entity: string | undefined) => {
        const usableEntity = entity?.toLowerCase()
        switch (usableEntity) {
            case "customlabel":
                return "labels";
            case "customtags":
                return "tags";
            case "customheader":
                return "header";
            case "customDimension":
                return "customDimensions";
            case "customdimensionvalue":
                return "dimensionValue";
            case "customExtensionElement":
                return "extensionElements";
            case "customSettings":
                return "settings";
            default:
                return "header";
        }
    };

    return (
        <ObjectsWrapper>
            <Grid container maxWidth={"false"} alignItems="center" sx={{ p: 2 }}>
                <Grid size={6}>
                    <Typography variant="h5" fontWeight={"bold"} sx={{ pl: 2, pb: 2, pt: 2 }}>
                        {t`Consistency Check`}
                    </Typography>
                </Grid>
                <Grid size={6} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCheck}
                    >
                        {t`Check`}
                    </Button>
                </Grid>
            </Grid>
            {(selectedId !== undefined) && (
                <CustomObjectsDialog
                    id={selectedId}
                    url={getUrl(selectedRow?.entity)}
                    open={selectedId !== undefined}
                    onClose={handleCloseDialog}
                    selectTemplate={getTemplate(selectedRow?.entity)}
                />
            )}
            <Container
                maxWidth={false}
                sx={{
                    minHeight: 750,
                    overflowY: 'auto',
                }}
            >
                {!data && !loading && (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 750 }}>
                        <Typography>
                            {t`Currently there are no results to display.`}
                        </Typography>
                    </Box>
                )}
                {loading && !data && (
                    <Loader
                        height={750}
                    />
                )}
                {Array.isArray(data) && (
                    <Box>
                        {data?.map((item: any) => (
                            <Box key={item.id} sx={{ mb: 3 }}>
                                <Typography variant="h4" fontWeight={"bold"} sx={{ pb: 2 }}>
                                    {item.taxonomyName}
                                </Typography>
                                {item.customLabels && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Labels`}</Typography>
                                        {item.customLabels.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {item?.customHeaders && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2, mt: 5 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Header`}</Typography>
                                        {item.customHeaders.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {item?.customTags && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2, mt: 5 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Tags`}</Typography>
                                        {item.customTags.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {item?.customSettings && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2, mt: 5 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Settings`}</Typography>
                                        {item.customSettings.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {item?.customDimension && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2, mt: 5 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Dimension`}</Typography>
                                        {item.customDimension.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {item?.customDimensionValue && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2, mt: 5 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Dimension Value`}</Typography>
                                        {item.customDimensionValue.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                {item?.customExtensionElement && (
                                    <Box sx={{ maxHeight: 1000, overflow: 'auto', borderRadius: 2, p: 2, mt: 5 }}>
                                        <Typography variant="h5" fontWeight={"bold"}>{t`Custom Extension Element`}</Typography>
                                        {item.customExtensionElement.map((column: consistencyLabel) => (
                                            <Box key={column?.id} sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="h5"
                                                    onClick={() => {
                                                        setSelectedId(column?.id);
                                                        setSelectedRow(column);
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: 'primary.main'
                                                    }}
                                                >
                                                    {column?.name}
                                                </Typography>
                                                {column?.errors && column.errors.map((error: cConsistencyError) => (
                                                    <Box key={error.id} sx={{ pl: 2, pt: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {error.message}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
            </Container>
        </ObjectsWrapper >
    );
}
