import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { TableColumnHeader } from "./tableHeader";
import { Apifilters } from "../api/customObjectsTypes";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { t } from "@lingui/core/macro";

const commonStyles = {
    color: "black",
    backgroundColor: 'white',
    borderRadius: '4px',
    '& .MuiSelect-select, & .MuiOutlinedInput-root': {
        fontSize: '0.875rem',
    },
};

export const FilterableCell = ({
    ch,
    filters,
    setFilter,
    columnOptions,
    inputType,
}: {
    ch: TableColumnHeader<any>;
    filters: Apifilters[];
    setFilter?: (id: string, value?: string | boolean | null, sortDecending?: boolean, enterPressed?: boolean) => void;
    columnOptions?: string[];
    inputType: "text" | "select";
}) => {
    const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

    const handleSelectChange = (id: string, value: string, shouldSetFilter: boolean) => {
        const newValue = value === "" ? null : value;
        setSelectedValues((prev) => ({ ...prev, [id]: value }));
        if (shouldSetFilter) {
            setFilter?.(id, newValue, undefined, true);
        }
    };

    const handleInputChange = (id: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value === "" ? null : event.target.value;
        setFilter?.(id, newValue);
        setSelectedValues((prev) => ({ ...prev, [id]: event.target.value }));
    };

    const handleSortClick = () => {
        const sortDescending = filters.find((f) => f.column === ch.id)?.sortDescending ?? true;
        setFilter?.(ch.id as string, undefined, !sortDescending, true);
    };

    const handleKeyPress = (id: string, event: any) => {
        const newValue = event.target.value === "" ? null : event.target.value;
        setFilter?.(id, newValue, undefined, event.key === "Enter");
        setSelectedValues((prev) => ({ ...prev, [id]: event.target.value }));
    };

    const value = selectedValues[ch.id as string] || '';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    sx={{ cursor: 'pointer', flex: 'none', marginRight: 1 }}
                    onClick={handleSortClick}
                >
                    {ch.label}
                </Typography>
                <SwapVertIcon
                    fontSize="small"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleSortClick}
                />
            </Box>
            <FormControl sx={{ flex: 1, ...commonStyles }} size="small" variant="outlined">
                <InputLabel sx={{ color: "black" }} shrink={!!value}>
                </InputLabel>
                {inputType === "select" ? (
                    <Select
                        value={value}
                        onChange={(e) => handleSelectChange(ch.id as string, e.target.value as string, true)}
                        sx={{
                            p: 0,
                            m: 0,
                            '& .MuiSelect-icon': {
                                color: 'black',
                            },
                        }}
                    >
                        {(!columnOptions || !columnOptions.includes("")) && (
                            <MenuItem value="null">
                                <em>{t`None`}</em>
                            </MenuItem>
                        )}
                        <MenuItem value="empty">
                            <em>{t`Empty`}</em>
                        </MenuItem>
                        {columnOptions && columnOptions.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                                {option === "" ? <em>{t`None`}</em> : option}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <TextField
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(e) =>
                            handleInputChange(ch.id as string, e)}
                        onKeyDown={(e) => handleKeyPress(ch.id as string, e)}
                        sx={{ ...commonStyles, flex: 1 }}
                        autoComplete="off"
                    />
                )}
            </FormControl>
        </Box>
    );
};

export default FilterableCell;
