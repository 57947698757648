import { ListItemText, FormControlLabel, Checkbox } from "@mui/material";
import Grid from '@mui/material/Grid2';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const CombinedCheckBoxLabel = ({
    label,
    value,
    onValueChange,
    alternative = false,
}: {
    label?: string;
    value?: boolean | null;
    onValueChange?: (newValue: boolean | null) => void;
    alternative?: boolean;
}) => {
    const handleClick = () => {
        let newValue: boolean | null;

        if (alternative) {
            newValue = value === null ? true : value === true ? false : null;
        } else {
            newValue = !value;
        }

        onValueChange?.(newValue);
    };

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <FormControlLabel
                    sx={{ p: 1, height: 10 }}
                    control={
                        <Checkbox
                            checked={alternative ? value === true : !!value}
                            indeterminate={alternative && value === null}
                            icon={alternative ? <CheckBoxOutlineBlankIcon /> : undefined}
                            indeterminateIcon={alternative ? <IndeterminateCheckBoxIcon /> : undefined}
                            checkedIcon={alternative ? <CheckBoxIcon /> : undefined}
                            onClick={handleClick}
                        />
                    }
                    label={undefined}
                />
            </Grid>
        </Grid>
    );
};
