import { jwtDecode } from "jwt-decode";
import { AccessTokenRespons } from "../api/types";

const tokenStorageHelper: {
  token: string;
  expireAt: string | null;
  isExpired: boolean;
  isValid: boolean;
  isInvalid: boolean;
  isUserLoggedIn: boolean;
  isUserCustomObjectAdmin: boolean;
  isUserCustomObjectEditor: boolean;
  userHasCustomObjectReader: boolean;
  userHasCustomObjectAccess: boolean;
  setTokenValues: (resp: AccessTokenRespons) => void;
  clear: () => void;
} = {
  get token() {
    return localStorage.getItem("UserToken") || "";
  },

  get expireAt() {
    return localStorage.getItem("ExpireAt");
  },

  get isExpired() {
    return tokenStorageHelper.expireAt
      ? tokenStorageHelper.expireAt < new Date().toISOString()
      : true;
  },

  get isValid() {
    return (tokenStorageHelper.token && !tokenStorageHelper.isExpired) || false;
  },

  get isInvalid() {
    return !tokenStorageHelper.isValid;
  },

  get isUserLoggedIn() {
    return tokenStorageHelper.isValid && tokenStorageHelper.token.length > 0;
  },

  get isUserCustomObjectAdmin() {
    const payload = tokenStorageHelper?.token ? jwtDecode<{ [key: string]: any }>(tokenStorageHelper.token) : null;
    const roles = payload?.["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    const role = Array.isArray(roles) ? roles.includes("CUSTOM_OBJECT_ADMINISTRATOR") : roles === "CUSTOM_OBJECT_ADMINISTRATOR";
    return !!role;
  },

  get userHasCustomObjectReader() {
    const payload = tokenStorageHelper?.token ? jwtDecode<{ [key: string]: any }>(tokenStorageHelper.token) : null;
    const roles = payload?.["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    const role = Array.isArray(roles) ? roles.includes("CUSTOM_OBJECT_READER") : roles === "CUSTOM_OBJECT_READER";
    return !!role;
  },

  get isUserCustomObjectEditor() {
    const payload = tokenStorageHelper?.token ? jwtDecode<{ [key: string]: any }>(tokenStorageHelper.token) : null;
    const roles = payload?.["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    const role = Array.isArray(roles) ? roles.includes("CUSTOM_OBJECT_EDITOR") : roles === "CUSTOM_OBJECT_EDITOR";
    return !!role;
  },

  get userHasCustomObjectAccess() {
    return tokenStorageHelper?.isUserLoggedIn && (tokenStorageHelper?.isUserCustomObjectEditor || tokenStorageHelper?.userHasCustomObjectReader || tokenStorageHelper?.isUserCustomObjectAdmin);
  },

  setTokenValues(tokenResponse: AccessTokenRespons) {
    localStorage.setItem("UserToken", tokenResponse.token);
    localStorage.setItem("ExpireAt", tokenResponse.expireAt.toString());
  },

  clear() {
    const customObjectsSelectedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    localStorage.clear();
    if (customObjectsSelectedTaxonomies) {
      localStorage.setItem("customObjectsSelectedTaxonomies", customObjectsSelectedTaxonomies);
    }
  },
};

export default tokenStorageHelper;
