import { ListItemText, Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { cTagGroup } from "../../api/customObjectsTypes";
import { generalGet } from "../../api/general";

export const TagGroupLabel = ({
    label,
    value,
    onValueChange,
    disabled,
    placeholder,
}: {
    label?: string;
    value?: string;
    onValueChange?: (event: string) => void;
    disabled?: boolean;
    placeholder?: string;
}) => {
    const [optionsState, setOptionsState] = useState<(cTagGroup | { name: string })[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await generalGet<cTagGroup[]>("api/configuration/tag-groups");
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setOptionsState([{ name: "null" }, ...sortedData]);
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid size={2}>
                <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} />
            </Grid>
            <Grid size={10}>
                <Autocomplete
                    options={optionsState}
                    getOptionLabel={(option) => option.name}
                    value={
                        optionsState.find((option) => option.name === value) || null
                    }
                    onChange={(_, newValue: cTagGroup | { name: string } | null) => {
                        if (onValueChange) {
                            onValueChange(newValue ? newValue.name : "");
                        }
                    }}
                    onBlur={(event) => {
                        const inputValue = (event.target as HTMLInputElement).value;
                        const matchingOptions = optionsState.filter(option =>
                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        if (matchingOptions.length === 1) {
                            onValueChange?.(matchingOptions[0].name);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder || ""}
                            inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            variant="standard"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};
