import { useState } from 'react';
import { UsageLogPayload, UsageLogResponse, UsageLogResult } from '../../api/customObjectsTypes';
import { t } from '@lingui/core/macro';
import DataTable from '../dataTable';
import { Container } from '@mui/material';
import { getSelectedTaxonomyIds } from '../../helpers/taxonomyHelpers';
import { usePostQuery } from '../../hooks/usePostQuery';
import { ColumnProps } from '../../api/types';

export default function UsageComponent({
    id,
    activeTab,
}: {
    id: string | undefined;
    activeTab: string;
}) {
    const activeTabSelector = () => {
        switch (activeTab) {
            case "customHeader":
                return "headers";
            case "customLabels":
                return "labels";
            case "customTags":
                return "tags";
            default:
                return "";
        }
    };

    const filters = [
        {
            column: "nameOfReportingEntity",
            filterType: 1,
        },
        {
            column: "timestamp",
            filterType: 1
        }
    ];

    const [req, setReq] = useState<UsageLogPayload>({
        page: 1,
        pageCount: 20,
        filters: filters,
        taxonomyIds: getSelectedTaxonomyIds(),
    });

    const { data, loading } = usePostQuery<UsageLogResponse>(
        id ? `api/custom-objects/${activeTabSelector()}/${id}/usage` : '',
        req
    );

    const handleSetFilter = (columnId: string, value?: string | boolean | null, sortDescending?: boolean) => {
        setReq((prevReq: UsageLogPayload) => {
            return {
                ...prevReq,
                filters: prevReq.filters.map((filter) => {
                    if (filter.column === columnId) {
                        return { ...filter, value: value === "" ? null : value, sortDescending };
                    }
                    return filter;
                }),
            };
        });
    };

    return (
        <Container maxWidth={false}>
            <DataTable<UsageLogResult>
                columns={columns()}
                filters={filters}
                loading={loading}
                data={data?.result || []}
                setFilter={handleSetFilter}
            />
        </Container>
    );
};

const columns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "nameOfReportingEntity",
                label: t`Entity`,
            },
            key: "nameOfReportingEntity",
            width: 200,
        },
        {
            header: {
                id: "timestamp",
                label: t`Timestamp`,
            },
            key: "timestamp",
            width: 200,
        },
    ];
};
