import { useState } from "react";
import DataTable, { DataTableProps } from "./dataTable";
import { useGetQuery } from "../hooks/useGetQuery";

export type activeTabTableOptions = "customLabels" | "customHeaders" | "customTags" | "contexts" | "customDimensionValues";

interface DataTableWithFetchProps<T extends { id: string }> extends DataTableProps<T> {
    id: string;
    activeTab: activeTabTableOptions;
    endpoint: string;
}

export default function DataTableWithFetch<T extends { id: string }>({
    id,
    activeTab,
    endpoint,
    ...dataTableProps
}: DataTableWithFetchProps<T>) {
    const defineEndpoint = (endpoint: string) => {
        switch (endpoint) {
            case "dimensionValue":
                return "dimension-values";
            case "dimensions":
                return "dimensions";
            case "extensionElements":
                return "elements";
            default:
                return endpoint;
        }
    };

    const defineTab = (activeTab: activeTabTableOptions) => {
        switch (activeTab) {
            case "customLabels":
                return "labels";
            case "customHeaders":
                return "headers";
            case "customTags":
                return "tags";
            case "contexts":
                return "contexts";
            case "customDimensionValues":
                return "dimension-values";
            default:
                return "";
        }
    };
    const [cacheBuster] = useState(crypto.randomUUID());
    const tab = defineTab(activeTab);
    const endPoint = defineEndpoint(endpoint);
    const { data, loading } = useGetQuery<T[]>(
        `api/custom-objects/${endPoint}/${id}/${tab}?cb=${cacheBuster}`
    );

    return (
        <DataTable
            {...dataTableProps}
            data={data ?? []}
            loading={loading}
            nested
        />
    );
}